export const domain = "https://artware2.datenbanker.at";

export function request(
  method: "POST" | "GET" | "PUT" | "DEL",
  endpoint: string,
  URLParams: { [key: string]: string },
  header: { [key: string]: string },
  body: { [key: string]: any }
): Promise<XMLHttpRequest> {
  const req = new XMLHttpRequest();
  req.open(method, endpoint);

  const urlParams = Object.entries(URLParams).map(([key, value]) => {
    return `${key}=${value}`;
  });

  if (urlParams.length > 0) {
    endpoint += "?" + urlParams.join("&");
  }

  for (const [key, value] of Object.entries(header)) {
    req.setRequestHeader(key, value);
  }
  req.setRequestHeader("content-type", "application/json;charset=UTF-8");

  const payload = JSON.stringify(body);
  req.send(payload);

  return new Promise((resolve, reject) => {
    req.onreadystatechange = function () {
      if (req.readyState === 4 && req.status === 200) {
        resolve(req);
      } else if (req.readyState === 4) reject(req);
    };
  });
}
/* 
export function useHttpRequest(
  method: "POST" | "PUT" | "GET" | "DELETE",
  url: string,
  urlParams: { [key: string]: string },
  headers: { [key: string]: string },
  body: { [key: string]: any }
) {
  const [state, setState] = useState({ status: "init", response: null });
  try {
    await request();
  } catch (error) {}
} */
