import React from "react";
//import { useClasses, ITheme } from "@maxeb/admin-ui";
//import { Row, Col, Container } from "@themeium/uui";
/*import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faEnvelope,
  faPhone,
} from "@fortawesome/pro-light-svg-icons"; */

export interface IProps {}

/*const style = (theme: ITheme) => ({
  footer: {
    backgroundColor: "#000",
    marginTop: "16px",
    color: "#fff",
    padding: "32px 0px",
  },
  list: {
    listStyle: "none",
    padding: "0 0 0 16px",
    margin: 0,
    [theme.breakpoints.down("md")]: {
      padding: "0",
    },
  },
  icon: {
    fontSize: "1.2rem",
    marginRight: "8px",
  },
  padding: {
    padding: "24px 0px 0px 0px",
  },
});*/

export default function Footer(props: IProps) {
  //const classes = useClasses(style, props);
  return <div />;
  /*   return (
    <footer className={classes.footer}>
      <Container>
        <Row spacing={16} root={{ left: 0, right: 0 }} vertical>
          <Col xs="hidden" sm="hidden" md={5} lg={20 / 3}>
            Artcare kommt aus der Erfassung und digitalen Archivierung von
            Kunstwerken. Im Jahr 2019 hat die Artcare-Gruppe einen etablierten
            Standort für zeitgenössische Kunst übernommen, wo seit über 20
            Jahren die Förderung zeitgenössischer und junger Kunst
            großgeschrieben wird. Auf über 400m² Showroom, Ateliers sowie Lager
            und Werkstätten gibt es alles für Sammler*innen wie auch für
            Künstler*innen. Für Unternehmen gibt es die Möglichkeit Kunst zu
            mieten und Büros und Geschäftsräumlichkeiten repräsentativ
            auszustatten.
            <br />
            <br />
            ATU 66203333
          </Col>
          <Col md={5} lg={10 / 3}>
            <ul className={classes.list}>
              <li>
                <FontAwesomeIcon className={classes.icon} icon={faBuilding} />
                Rechte Bahngasse 30-32, 1030 Wien
              </li>
              <li className={classes.padding}>
                <FontAwesomeIcon className={classes.icon} icon={faPhone} />
                +43 (0) 676 432 74 31
              </li>
              <li className={classes.padding}>
                <FontAwesomeIcon className={classes.icon} icon={faEnvelope} />
                office@artcare.at
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  ); */
}
