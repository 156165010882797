import React, { createRef, useEffect, useState } from "react";
import IsVisble from "./IsVisble";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { useClasses, ITheme, Container, Row, IRow } from "@maxeb/admin-ui";

export interface IProps extends IRow {
  pageSize: number;
  children: React.ReactNode;
  height: string | number;
}

function getRowProps(props: IProps) {
  return {
    horizontalAlign: props.horizontalAlign,
    verticalAlign: props.verticalAlign,
    flow: props.flow,
    parts: props.parts,
    root: props.root,
    spacing: props.spacing,
    vertical: props.vertical,
    override: props.override,
  };
}

const styles = (theme: ITheme) => ({
  wrapper: {
    padding: "32px 0px 0px 0px",
    width: "100%",
    height: "calc(100vh - 110px)",
    overflow: "scroll",
    "-ms-overflow-style": "none,",
    scrollbars: "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "@media only screen and (max-width: 1146px)": {
      paddingTop: "70px",
      height: "calc(100vh - 148px)",
    },
    [theme.breakpoints.down("lg")]: {
      padding: "16px 0px 0px 0px",
      height: "calc(100vh - 65px)",
    },
  },
  spinner: {
    textAlign: "center",
    color: theme.palette.get("primary"),
    fontSize: "4rem",
    padding: "16px 0px 0px 0px",
  },
});

let isUpScroll = false;
let isDownScroll = false;

function ItemLimiter(props: IProps) {
  const classes = useClasses(styles, props);
  //get props
  const children = React.Children.toArray(props.children);
  const { pageSize } = props;
  const rowProps = getRowProps(props);
  //handle state
  const [state, setState] = useState({ start: 0, end: pageSize });

  const wrapper = createRef<HTMLDivElement>();

  //ugly side effects for scrolling
  useEffect(() => {
    if (wrapper.current && isUpScroll) {
      isUpScroll = false;
      const lastScroll = wrapper.current.scrollTop;
      wrapper.current.scrollTop =
        wrapper.current.scrollHeight / 2 -
        103 +
        (state.start === 0 ? 0 : lastScroll);
    }

    if (wrapper.current && isDownScroll && state.end <= children.length) {
      isDownScroll = false;
      wrapper.current.scrollTop =
        wrapper.current.scrollHeight / 2 + 103 - wrapper.current.clientHeight;
    }
  });

  const prev = async (isVisible: boolean) => {
    if (state.start !== 0 && isVisible) {
      isUpScroll = true;

      const end = state.end - pageSize;
      const start = state.start - pageSize > 0 ? state.start - pageSize : 0;

      setState({
        ...state,
        start,
        end,
      });
    }
  };

  const next = (isVisible: boolean) => {
    if (isVisible) {
      isDownScroll = true;

      const end = state.end + pageSize;
      let start = state.start + pageSize;
      if (end > children.length) start = state.start;

      setState({
        ...state,
        start,
        end,
      });
    }
  };

  const Pending = (
    <div className={classes.spinner}>
      <FontAwesomeIcon icon={faSpinnerThird} spin />
    </div>
  );

  return (
    <div className={classes.wrapper} ref={wrapper}>
      <Container>
        {state.start > 0 && Pending}
        {state.start > 0 && <IsVisble onChange={prev} />}
        <Row {...rowProps}>
          {children.slice(state.start, state.end + pageSize)}
        </Row>
        {state.end < children.length - 1 && <IsVisble onChange={next} />}
        {state.end < children.length && Pending}
      </Container>
    </div>
  );
}

export default ItemLimiter;
