import React from "react";
import ErrorBlock from "../Shared/ErrorBlock";
import ArtistAtArtwork, { IProps as IHost } from "./ArtistAtArtwork";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons";

export interface IProps {
  id?: number;
}
export interface IState {
  data: IHost | null;
  pending: boolean;
  unknownError: boolean;
}

export default class Artworks extends React.Component<IProps, IState> {
  MOUNT = false;
  constructor(props: IProps) {
    super(props);
    this.state = {
      data: null,
      pending: false,
      unknownError: false,
    };
    this.load = this.load.bind(this);
  }
  componentDidMount() {
    this.MOUNT = true;
    this.load();
  }
  componentWillUnmount() {
    this.MOUNT = false;
  }
  async load() {
    this.setState({ ...this.state, pending: true });
    try {
      const received = await fetch(
        "https://artware2.datenbanker.at/api/artwork/" +
          this.props.id +
          "/public"
      ).then((response) => response.json());
      const got = received.artwork.data;
      const data: IState["data"] = {
        id: got.id,
        name: got.title,
        description: got.description,
        thumbExtension: got.originalThumbExtension,
        thumbHeight: got.originalThumbHeight,
        thumbId: got.originalThumbId,
        thumbName: got.originalThumbName,
        thumbUpdateDate: got.originalThumbUpdateDate,
        thumbWidth: got.originalThumbWidth,
      };
      if (this.MOUNT) this.setState({ ...this.state, data, pending: false });
    } catch (error) {
      if (this.MOUNT)
        this.setState({ ...this.state, pending: false, unknownError: true });
    }
  }

  render() {
    if (this.state.pending)
      return (
        <div style={{ textAlign: "center", padding: "36px 0px" }}>
          <FontAwesomeIcon
            style={{ fontSize: "4rem", color: "#246cb5" }}
            icon={faSpinnerThird}
            spin
          />
          <p style={{ color: "#7d7d7d", fontWeight: 100 }}>Wird geladen...</p>
        </div>
      );
    if (this.state.unknownError || this.state.data == null)
      return <ErrorBlock text="Werke werden geladen..." />;
    const data = this.state.data;
    return (
      <div
        style={{
          backgroundColor: "#000",
          color: "#fff",
          margin: "0px -16px -16px -16px",
          padding: "16px",
        }}
      >
        <ArtistAtArtwork
          portrait
          center
          key={"host-" + data.id}
          id={data.id}
          name={data.name}
          description={data.description}
          thumbExtension={data.thumbExtension}
          thumbHeight={data.thumbHeight}
          thumbWidth={data.thumbWidth}
          thumbId={data.thumbId}
          thumbName={data.thumbName}
          thumbUpdateDate={data.thumbUpdateDate}
        />
      </div>
    );
  }
}
