import { useClasses, ITheme, Row, Col } from "@maxeb/admin-ui";
import { Link } from "react-router-dom";
import Block from "../Shared/Block";
import Headline from "../Shared/Headline";

const styles = (theme: ITheme) => ({
  wrapper: {
    textDecoration: "none !important",
    color: theme.palette.get("font"),
  },
  logo: {
    maxWidth: "100%",
    [theme.breakpoints.up("md")]: {
      maxWidth: "250px",
    },
  },
});

export interface IProps {
  id: string;
  name: string;
  description: React.ReactNode;
  logo: string;
  archive?: boolean;
}

function AuctionsListItem(props: IProps) {
  const classes = useClasses(styles);
  return (
    <Link
      className={classes.wrapper}
      to={`/auctions${props.archive ? "/archive" : ""}/${props.id}`}
    >
      <Block>
        <Row
          spacing={16}
          vertical
          root={{ top: 0, left: 0, right: 0, bottom: 0 }}
        >
          <Col md={"auto"}>
            <img
              src={props.logo}
              className={classes.logo}
              alt={`${props.name}-logo`}
            />
          </Col>
          <Col md={"rest-fixed"}>
            <Headline variant="subheadline">{props.name}</Headline>
            <p>{props.description}</p>
          </Col>
        </Row>
      </Block>
    </Link>
  );
}

export default AuctionsListItem;
