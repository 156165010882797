import React from "react";
import Block from "../Shared/Block";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  useClasses,
  ITheme,
} from "@maxeb/admin-ui";
import Menu from "./Menu";
import Headline from "../Shared/Headline";
import artcare from "../../resources/images/acbgc.jpg";
import flomyca from "../../resources/images/flomyca.jpg";
import Footer from "../Shared/Footer";

export interface IProps {}

const places = [
  {
    name: "Artcare",
    location: "Rechtebahngasse 30-32, 1130 Wien",
    img: artcare,
    link: "https://crm.zoho.eu/bookings/ARTCARE1030WienWerkebesichtigen?rid=d9febba20eb3c599da9c6973992b20e390ec5dc8a58aba629a6c55d2777b5770gid6a2ccccf2ae92be23cb1704ff6b0a110ac3f5472a887df68e4a6c7277078e40e",
  },
  {
    name: "Flomyca",
    location: "Rechtebahngasse 30-32, 1130 Wien",
    img: flomyca,
    link: "https://crm.zoho.eu/bookings/FLOMYCABesichtigungbuchen?rid=d9febba20eb3c599da9c6973992b20e36decdcd2cd56d912a79fa046d4401d59gid6a2ccccf2ae92be23cb1704ff6b0a110ac3f5472a887df68e4a6c7277078e40e",
  },
];

const styles = (theme: ITheme) => ({
  logo: {
    width: "150px",
  },
});

export default function Book(props: IProps) {
  const classes = useClasses(styles);

  return (
    <>
      <Container>
        <Row spacing={16} vertical root={{ top: 0, left: 0, right: 0 }}>
          <Menu />
          <Col>
            <Headline variant="bodyheadline" noMargin>
              Standorte
            </Headline>
          </Col>
          {places.map((place) => (
            <Col md={5} key={`${place.name}-places`}>
              <Row spacing={16} vertical root={{ top: 0, left: 0, right: 0 }}>
                <Col>
                  <Block>
                    <Row
                      spacing={16}
                      vertical
                      root={{ top: 0, left: 0, right: 0 }}
                    >
                      <Col md="auto">
                        <img
                          className={classes.logo}
                          src={place.img}
                          alt={`${place.name}-logo`}
                        />
                      </Col>
                      <Col md="rest-fixed">
                        <Headline variant="subheadline">{place.name}</Headline>
                        <p>{place.location}</p>
                        <Headline variant="subheadline" noMargin>
                          Besichtigung
                        </Headline>
                        <p>
                          Gerne können Sie bei uns die Bilder am Standort{" "}
                          <b>{place.name}</b> betrachten.
                        </p>
                        <Form
                          vertical
                          onSubmit={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <Row horizontalAlign="right" root={0}>
                            <Button
                              xs="100px"
                              primary
                              onClick={() => {
                                window.open(place.link, "_blank");
                              }}
                            >
                              Besichtigen
                            </Button>
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  </Block>
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      </Container>
      <Footer />
    </>
  );
}
