import React, { useEffect, useState } from "react";
import Block from "../Shared/Block";
import {
  useClasses,
  ITheme,
  Container,
  Row,
  Col,
  Form,
  Button,
} from "@maxeb/admin-ui";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons/faSpinnerThird";
import ArtworkBid from "./Bid";
import ArtworkInterest from "./Interest";
import ArtworkSelling from "./Selling";
import { IProps as IArtworkProps } from "./Artwork";
import Axios from "axios";
import { faCheck, faExclamation } from "@fortawesome/pro-light-svg-icons";
import { faShareAlt } from "@fortawesome/pro-solid-svg-icons";
import Artist from "./Artist";

interface nav extends Navigator {
  share: any;
}

declare var navigator: nav;

export interface IProps {
  mode: IArtworkProps["mode"];
  code?: string;
  confirmation?: true;
  id: number;
  artist?: string;
  artistContent?: number;
  title?: string;
  mockup?: string | null;
  thumb?: string | null;
  lot?: number;
  description?: string;
  category?: string;
  technique?: string;
  artistDescription?: string;
  width?: number;
  height?: number;
  depth?: number;
  price?: number;
  year?: number;
  bid?: string;
  available?: number;
  onClose: () => void;
  usersBid?: string | null;
  startPrice?: number;
  end: Date;
}
export interface IState {}

const style = (theme: ITheme) => ({
  lightboxShadow: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.8)",
    zIndex: 901,
    padding: "16px",
    boxSizing: "border-box",
    overflowY: "scroll",
    [theme.breakpoints.up("md")]: {
      paddingTop: "72px",
    },
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  lightbox: {
    display: "inline-block",
    verticalAlign: "middle",
    width: "100%",
  },
  wrapper: {
    position: "relative",
  },
  lot: {
    color: theme.palette.get("primary"),
    padding: "0px 8px 0px 0px",
    fontSize: "3rem",
    lineHeight: "2rem",
    fontWeight: 600,
  },
  lineHolder: {
    position: "relative",
  },
  line: {
    position: "absolute",
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "100%",
    textOverflow: "ellipsis",
  },
  artist: {
    top: "-5px",
  },
  title: {
    top: "22px",
    color: theme.palette.get("grey"),
    fontSize: "0.8rem",
    fontWeight: 100,
  },
  img: {
    maxWidth: "100%",
  },
  pendingMockup: {
    height: "150px",
    textAlign: "center",
    color: theme.palette.get("primary"),
    padding: "20px 0px",
    boxSizing: "border-box",
    fontSize: "4rem",
  },
  subHeading: {
    color: theme.palette.get("primary"),
    margin: "0px 0px 8px 0px",
  },
  data: {
    color: theme.palette.get("grey"),
    fontWeight: 100,
  },
  price: {
    fontWeight: 400,
  },
  bidContainer: { textAlign: "center" },
  info: {
    display: "block",
    textAlign: "right",
    color: theme.palette.get("grey"),
    fontSize: "0.8rem",
  },
  badge: { color: "#fff", padding: "0px 8px", borderRadius: "4px" },
  primary: {
    backgroundColor: theme.palette.get("primary"),
  },
  danger: {
    backgroundColor: theme.palette.get("danger"),
  },
  success: {
    backgroundColor: theme.palette.get("success"),
  },
  warning: {
    backgroundColor: theme.palette.get("warning"),
  },
  confirmButtonHolder: {
    textAlign: "right",
  },
  confirmButton: {
    backgroundColor: theme.palette.get("primary"),
    border: "none",
    outline: "none",
    color: "#fff",
    fontSize: "2rem",
    padding: "8px 16px",
    borderRadius: "4px",
    cursor: "pointer",
  },
  spinner: {
    fontSize: "4rem",
    color: theme.palette.get("primary"),
    margin: "24px auto",
    display: "block",
  },
  successIcon: {
    fontSize: "4rem",
    color: "#fff",
    backgroundColor: theme.palette.get("success"),
    padding: "16px 16px",
    borderRadius: "100%",
    margin: "0px",
    display: "block",
  },
  errorIcon: {
    fontSize: "4rem",
    color: "#fff",
    backgroundColor: theme.palette.get("danger"),
    padding: "16px 36px",
    borderRadius: "100%",
    margin: "0px",
    display: "block",
  },
  infoText: {
    fontSize: "0.8rem",
    color: "#000",
    marginTop: "0px",
  },
  dangerText: {
    color: theme.palette.get("danger"),
  },
});

async function confirm(
  id: number,
  code: string,
  setState: (state: string) => void,
  displayedValue: string
) {
  try {
    setState("pending");
    const result = await Axios.post(
      "https://artware2.datenbanker.at/api/confirmation",
      {
        artwork: id,
        code,
        displayedValue,
      }
    );

    if (result.data.error === false) setState("success");
    else if (result.data.status === "sold") setState("sold");
    else setState("error");
  } catch (error) {
    setState("error");
  }
}

export default function ArtworkDetails(props: IProps) {
  const classes = useClasses(style, { ...props });
  const [bidding, setBidding] = useState(false);
  const [interest, setInterest] = useState(false);
  const [selling, setSelling] = useState(false);
  const [state, setState] = useState("ok");

  const onClose = props.onClose;

  useEffect(() => {
    document.body.style.overflow = "hidden";
    window.onpopstate = () => {
      onClose();
    };

    return () => {
      window.onpopstate = null;
      document.body.style.overflow = "auto";
    };
  }, [onClose]);

  const price =
    typeof props.price === "string" ? parseInt(props.price) : props.price;
  const bid = typeof props.bid === "string" ? parseInt(props.bid) : props.bid;
  const confirmation = props.confirmation;
  const currentTime = new Date();
  return (
    <div className={classes.lightboxShadow}>
      <div className={classes.lightbox}>
        <Container spacing={0}>
          <Row>
            <Col>
              <Block>
                <div className={classes.wrapper}>
                  <Row spacing={16} vertical root={0} verticalAlign="bottom">
                    {props.mockup !== null && (
                      <Col lg={2}>
                        {typeof props.mockup === "undefined" && (
                          <div className={classes.pendingMockup}>
                            <FontAwesomeIcon icon={faSpinnerThird} spin />
                          </div>
                        )}
                        {Boolean(props.mockup) && (
                          <img
                            className={classes.img}
                            alt="mockup"
                            src={props.mockup}
                          />
                        )}
                      </Col>
                    )}
                    <Col lg={"auto"}>
                      {props.thumb !== null &&
                        typeof props.thumb !== "undefined" && (
                          <img
                            alt="thumb"
                            className={classes.img}
                            src={props.thumb}
                          />
                        )}
                    </Col>
                    <Col lg="rest-fixed">
                      <div>
                        <Row root={{ bottom: 0 }}>
                          <Col xs="auto">
                            <span className={classes.lot}>
                              {props.lot || props.id}
                            </span>
                          </Col>
                          <Col xs="rest-fixed">
                            <Row>
                              <Col>
                                <div className={classes.lineHolder}>
                                  <span
                                    className={classNames([
                                      classes.line,
                                      classes.artist,
                                    ])}
                                  >
                                    {props.artist}
                                  </span>
                                </div>
                              </Col>
                              <Col>
                                <div className={classes.lineHolder}>
                                  <span
                                    className={classNames([
                                      classes.line,
                                      classes.title,
                                    ])}
                                  >
                                    {props.title}
                                  </span>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                      <Row
                        spacing={16}
                        vertical
                        root={{ bottom: 0, left: 0, right: 0 }}
                      >
                        <Col xs={5} lg={10 / 2}>
                          <span className={classes.data}>
                            {props.width}x{props.height}x{props.depth}cm
                          </span>
                        </Col>
                        <Col xs={5} lg={10 / 2}>
                          <span className={classes.data}>{props.year}</span>
                        </Col>
                        <Col xs={5} lg={10 / 2}>
                          <span className={classes.data}>{props.category}</span>
                        </Col>
                        <Col xs={5} lg={10 / 2}>
                          <span className={classes.data}>
                            {props.technique}
                          </span>
                        </Col>
                        {(props.mode === "SELLING" ||
                          props.mode === "TRANSITION") &&
                          props.bid !== "0.00" &&
                          props.bid !== null && (
                            <Col xs={10}>
                              <span
                                className={classNames([
                                  classes.badge,
                                  classes.primary,
                                ])}
                              >
                                Aktuelles Gebot: {(bid || 0)?.toLocaleString()}€
                              </span>
                            </Col>
                          )}
                        {props.mode === "SHOWING" && props.available === 0 && (
                          <Col xs={10}>
                            <span
                              className={classNames([
                                classes.badge,
                                classes.danger,
                              ])}
                            >
                              Verkauft um: {(bid || 0)?.toLocaleString()}€
                            </span>
                          </Col>
                        )}
                        {props.mode === "SHOWING" && props.available === 1 && (
                          <Col xs={10}>
                            <span
                              className={classNames([
                                classes.badge,
                                classes.warning,
                              ])}
                            >
                              Nicht Verfügbar
                            </span>
                          </Col>
                        )}
                        {props.mode === "SHOWING" &&
                          (props.available || 0) > 1 &&
                          price &&
                          props.startPrice && (
                            <Col xs={5} lg={5}>
                              <span
                                className={classNames([
                                  classes.badge,
                                  classes.success,
                                ])}
                              >
                                Preis: {props.startPrice.toLocaleString()}€
                              </span>
                            </Col>
                          )}
                        <Row>
                          {(props.mode === "SELLING" ||
                            props.mode === "TRANSITION") && (
                            <Col xs={5} lg={5}>
                              <span className={classes.price}>
                                Rufpreis: {props.startPrice?.toLocaleString()}€
                              </span>
                            </Col>
                          )}
                          {(props.mode === "SELLING" ||
                            props.mode === "TRANSITION") && (
                            <Col xs={5} lg={5}>
                              <span className={classes.price}>
                                Listenpreis: {price?.toLocaleString()}€
                              </span>
                            </Col>
                          )}
                        </Row>
                        {bidding && (
                          <ArtworkBid
                            id={props.id || 0}
                            lot={props.lot || 0}
                            onClose={() => {
                              setBidding(false);
                            }}
                          />
                        )}
                        {interest && (
                          <ArtworkInterest
                            lot={props.lot || 0}
                            onClose={() => {
                              setInterest(false);
                            }}
                          />
                        )}
                        {selling && (
                          <ArtworkSelling
                            lot={props.lot || 0}
                            onClose={() => {
                              setSelling(false);
                            }}
                          />
                        )}
                      </Row>
                      {state === "ok" &&
                        confirmation &&
                        (props.available || 0) > 0 && (
                          <Col>
                            <p>
                              Sie bestätigen, dass Sie bis zu einem Gebot von{" "}
                              <b>{props.usersBid}€</b> mitsteigern.
                            </p>
                            <div className={classes.confirmButtonHolder}>
                              <button
                                className={classes.confirmButton}
                                onClick={(e) => {
                                  e.preventDefault();
                                  confirm(
                                    props.id,
                                    props.code || "",
                                    setState,
                                    price + "€ "
                                  );
                                }}
                              >
                                Bestätigen
                              </button>
                            </div>
                          </Col>
                        )}
                      {state === "pending" && confirmation && (
                        <Col>
                          <FontAwesomeIcon
                            icon={faSpinnerThird}
                            className={classes.spinner}
                            spin
                          />
                        </Col>
                      )}
                      {state === "success" && confirmation && (
                        <Col>
                          <Row
                            spacing={16}
                            vertical
                            root={{ left: 0, right: 0, bottom: 0 }}
                          >
                            <Col xs={"112px"} xl="112px">
                              <FontAwesomeIcon
                                icon={faCheck}
                                className={classes.successIcon}
                              />
                            </Col>
                            <Col xs="calc(100% - 112px)">
                              <h2 style={{ margin: "16px 0px 0px 0px" }}>
                                Herzlichen Dank!
                              </h2>
                              <p className={classes.infoText}>
                                Ihr Höchstgebot wurde hinterlegt.
                              </p>
                            </Col>
                          </Row>
                        </Col>
                      )}
                      {state === "error" && confirmation && (
                        <Col>
                          <Row
                            spacing={16}
                            vertical
                            root={{ left: 0, right: 0, bottom: 0 }}
                          >
                            <Col xs={"112px"}>
                              <FontAwesomeIcon
                                icon={faExclamation}
                                className={classes.errorIcon}
                              />
                            </Col>
                            <Col xs="calc(100% - 112px)">
                              <h2 style={{ margin: 0 }}>Fehler!</h2>
                              <p className={classes.infoText}>
                                Es tut uns Leid, ein unbekannter Fehler ist
                                aufgetreten bitte kontaktieren Sie uns direkt
                                unter{" "}
                                <a href="mailTo:office@artcare.at">
                                  office@artcare.at
                                </a>
                                .
                              </p>
                            </Col>
                          </Row>
                        </Col>
                      )}
                      {confirmation && props.available === 0 && (
                        <Col>
                          <p style={{ marginBottom: "3px" }}>
                            Es tut uns leid, dass Werk wurde bereits verkauft.
                          </p>
                        </Col>
                      )}
                      {!confirmation && !bidding && !interest && !selling && (
                        <Col>
                          <p
                            style={{ margin: "16px 0px 0px 0px" }}
                            dangerouslySetInnerHTML={{
                              __html: props.description || "",
                            }}
                          />
                        </Col>
                      )}
                      {props.mode === "SHOWING" &&
                        (props.available || 0) < 2 &&
                        !bidding && (
                          <Row>
                            <span className={classes.info}>
                              Interesse an ähnlichen Werken.
                            </span>
                          </Row>
                        )}
                      {props.mode === "TRANSITION" && (
                        <Col>
                          <p
                            style={{
                              margin: "16px 0px 0px 0px",
                              color: "#7d7d7d",
                            }}
                          >
                            Der Nachverkauf beginnt in wenigen Tagen.
                          </p>
                        </Col>
                      )}
                      {props.mode === "SELLING" && props.end < currentTime && (
                        <p className={classes.dangerText}>
                          Dieses Lot ist bereits abgelaufen.
                        </p>
                      )}
                      {!confirmation && !bidding && !interest && !selling && (
                        <Form
                          spacing={16}
                          vertical
                          root={{ left: 0, right: 0, bottom: 0 }}
                          horizontalAlign="right"
                          onSubmit={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <Button xs="120px" onClick={props.onClose}>
                            Schließen
                          </Button>
                          {props.mode === "SHOWING" &&
                            (props.available || 0) > 1 && (
                              <Button
                                xs="120px"
                                success
                                onClick={() => {
                                  setSelling(true);
                                }}
                              >
                                Kaufen
                              </Button>
                            )}
                          {props.mode === "SHOWING" &&
                            (props.available || 0) < 2 && (
                              <Button
                                xs="120px"
                                primary
                                onClick={() => {
                                  setInterest(true);
                                }}
                              >
                                Interesse
                              </Button>
                            )}
                          {props.mode === "SELLING" && props.end > currentTime && (
                            <Button
                              xs="120px"
                              success
                              onClick={() => {
                                setBidding(true);
                              }}
                            >
                              Bieten
                            </Button>
                          )}
                          {navigator && navigator?.share && (
                            <Button
                              xs="48px"
                              primary
                              onClick={() => {
                                navigator
                                  .share({
                                    title:
                                      "LOT " + props.lot + " - " + props.artist,
                                    text: props.description,
                                    url:
                                      "https://auction.artcare.at/artwork/" +
                                      props.lot,
                                  })
                                  .then(() => console.log("Successful share"))
                                  .catch(() => console.log("Error sharing"));
                              }}
                            >
                              <FontAwesomeIcon
                                style={{ marginTop: 2 }}
                                icon={faShareAlt}
                              />
                            </Button>
                          )}
                        </Form>
                      )}
                    </Col>
                    {!confirmation && (
                      <Col>
                        <Artist id={props.artistContent} />
                      </Col>
                    )}
                  </Row>
                </div>
              </Block>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
