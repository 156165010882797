import { useClasses, ITheme, Container, Col, Row } from "@maxeb/admin-ui";
import Block from "./Shared/Block";
import Footer from "./Shared/Footer";
import Headline from "./Shared/Headline";

const styles = (theme: ITheme) => ({
  rentList: {
    textAlign: "center",
    marginTop: "70px",
  },
  list: {
    margin: 0,
  },
  noTextDecoration: {
    textDecoration: "none",
    color: "#3e3e3e;",
  },
});

function Service() {
  const classes = useClasses(styles);
  return (
    <div style={{ paddingTop: "16px" }}>
      <Container>
        <Row spacing={16} vertical root={0}>
          <Col stretch md={5}>
            <Block stretch>
              <Headline variant="h1">Artcare am Puls der Kunst</Headline>
              <p>
                <ul className={classes.list}>
                  <li>Kunstprojekte & Künstlerateliers</li>
                  <li>Kunstberatung & -vermittlung</li>
                  <li>Kunsthandel, Auktionen</li>
                  <li>Kunstmiete & Staging (Probehängung mit Kaufoption)</li>
                  <li>
                    Rahmung, Versicherung, Lieferung, Einlagerung, Hängung
                  </li>
                  <li>Kunstarchivierung & Digitalisierung</li>
                </ul>
              </p>
            </Block>
          </Col>
          <Col stretch md={5}>
            <Block stretch>
              <Headline variant="h1">Kunstberatung & -vermittlung</Headline>
              <p>
                „Der Zugang zur Kunst wird Ihr Leben verändern. Jeder Zugang ist
                richtig.“ Das ist unser Leitspruch. Wir wollen Kunst auf
                Augenhöhe vermitteln und der Katalysator sein für die Freude,
                die Kunst ins Leben bringt. Egal ob Kunst als Erweiterung einer
                Sammlung, als ästhetisches Element oder Investment gekauft wird,
                sie stellt immer eine Bereicherung dar. Die Kunstexpert*innen
                der Artcare können durch ihre langjährige Erfahrung im
                internationalen Kunstmarkt selektive Beratung anbieten. Wir
                decken dabei den strukturierten Aufbau von Kunstsammlungen
                genauso ab, wie die Beratung von Menschen, die ihr erstes
                Kunstwerk kaufen.
              </p>
            </Block>
          </Col>
          <Col stretch md={5}>
            <Block stretch>
              <Headline variant="h1">Kunsthandel & Auktionen</Headline>
              <p>
                Artcare ist ein eingetragenes Auktionshaus und veranstaltet
                regelmäßig Online-Auktionen, deren bekannteste wohl die YOUNG
                ART AUCTION, eine Förderauktion für Künstler*innen unter 35,
                ist. Auch die MOST WANTED FEMALE* ART AUCTION ist ein
                etabliertes jährliches Format zum Weltfrauentag. Darüber hinaus
                gibt es laufend themenbezogene Auktionen mit unterschiedlichen
                Schwerpunkten. Bei unseren Auktionen legen wir einen starken
                Fokus auf die Einbringung von Werken direkt aus den
                Künstlerateliers. Artcare schafft durch die Kombination aus
                direkter Einbringung durch die Künstler*innen und Online-Auktion
                einen neuen Primärmarkt für Kunst. Über 1.000m²
                Ausstellungsfläche an 2 Standorten in Wien ermöglichen es uns,
                alle Online gezeigten Werke in eigenen Ausstellungen zu zeigen.
              </p>
            </Block>
          </Col>
          <Col md={5}>
            <Block>
              <Headline variant="h1">Kunstmiete & Staging</Headline>
              <p>
                Bei der Kunstmiete arbeiten wir gemeinsam mit Ihnen Mietkonzepte
                zur Kunstausstattung Ihrer Geschäftsräume unter Berücksichtigung
                Ihrer Unternehmensphilosophie aus.
              </p>
              <Headline variant="subheadline">Und was ist Staging?</Headline>
              <p>
                Beim Staging bekommen unsere Kund*innen die Möglichkeit, sich
                Kunst zu „erlieben“. Und so funktionierts:
                <br />
                Im Rahmen von Projekten statten wir private Wohnobjekte und
                Unternehmen mit Kunst aus. Unsere Projektpartner*innen – das
                können Private, Innenarchitekt*innen und Bauträger*innen sein -
                wählen auf&nbsp;
                <a
                  className={classes.noTextDecoration}
                  href="https://artcare.at"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://artcare.at
                </a>
                &nbsp;Werke aus, die wunschgemäß gerahmt, probeweise in ihren
                Räumlichkeiten gehängt werden. Während der Probehängung, die bis
                zu 6 Monate dauern kann, haben unsere Projektpartner*innen Zeit,
                sich für den Ankauf oder die Anmietung der Werke zu entscheiden.
              </p>
            </Block>
          </Col>
          <Col stretch md={5}>
            <Block stretch>
              <Headline variant="h1">Kunstprojekte & Künstlerateliers</Headline>
              <p>
                Schon seit Eröffnung des Standortes in der Rechten Bahngasse
                wurde vor allem jungen Künstler*innen eine Arbeitsstätte geboten
                und so der Übergang vom Studium in den Kunstmarkt unterstützt.
                Aufgrund der großen Nachfrage erweitern wir dieses Angebot in
                einer ehemaligen Produktionsstätte in Floridsdorf, Jedlesee.
              </p>
            </Block>
          </Col>
          <Col stretch md={5}>
            <Block stretch>
              <Headline variant="h1">
                FLOMYCA - Floridsdorf Museum for Young & Contemporary Art
              </Headline>
              <p>
                FLOMYCA entsteht am Standort 1210 Wien, Voltagsse 42, und ist
                ein neu definierter Raum für den Produktionsprozess und die
                Vermittlung von zeitgenössischer, bildender Kunst. Der
                künstlerische Nährboden für das FLOMYCA ist die faszinierende
                internationale Kunstszene, die durch die beiden Wiener
                Kunsthochschulen – Universität für Angewandte Kunst und Akademie
                der bildenden Künste - repräsentiert wird.
              </p>
            </Block>
          </Col>
          <Col stretch md={5}>
            <Block stretch>
              <Headline variant="h1">
                Rahmung, Versicherung, Lieferung, Einlagerung, Hängung
              </Headline>
              <p>
                In unserer eigenen Rahmenwerkstatt stellen wir fachgerecht und
                individuell die passenden Rahmen her. Auf Wunsch liefert und
                hängt unser Logistikteam Ihre Werke. Und wenn Ihnen die Wände
                ausgehen, lagern wir Ihre Kunstwerke ein. Eingelagerte und
                vermietete Werke sind versichert.
              </p>
            </Block>
          </Col>
          <Col stretch md={5}>
            <Block stretch>
              <Headline variant="h1">
                Kunstarchivierung & Digitalisierung
              </Headline>
              <p>
                Mit unserer Software Webcollector erfassen und digitalisieren
                wir Sammlungen. Durch den freien Zugang für Künstler*innen
                können deren Werke jederzeit archiviert und verwaltet werden.
                Mit einem Klick haben die Künstler*innen somit Zugang zu einer
                stark wachsenden Kunstplattform&nbsp;
                <a
                  className={classes.noTextDecoration}
                  href="https://artcare.at"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://artcare.at
                </a>
                .
              </p>
            </Block>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Service;
