import React, { useEffect } from "react";
import { useClasses, ITheme, Row, Col, Button, Form } from "@maxeb/admin-ui";
import Block from "./Shared/Block";
import Lightbox from "./Shared/Lightbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import {
  faSpinnerThird,
  faTheaterMasks,
} from "@fortawesome/pro-duotone-svg-icons";
import { faHeart, faShareAlt } from "@fortawesome/pro-light-svg-icons";
import { getWishlistElement, TWishlist } from "../containers/Wishlist";
import classnames from "classnames";

export interface IProps {
  wishlist: TWishlist;
  onClose: () => void;
  id?: number;
  available?: number;
  artist?: string;
  title?: string;
  size?: string;
  price?: string;
  thumbUrl?: string;
  thumb?: string | null;
  year?: number | null;
  category?: string | null;
  technique?: string | null;
  description?: string;
  artistDescription?: string | null;
  thumbStatus: "pending" | "ok" | "none";
  mockup?: string | null;
  mockupStatus: "pending" | "ok" | "none";
}

const styles = (theme: ITheme) => ({
  alignment: {
    marginTop: "63px",
    [theme.breakpoints.down("lg")]: {
      marginTop: "8px",
    },
  },
  wrapper: {
    position: "relative",
  },
  closeIcon: {
    position: "absolute",
    right: "-12px",
    top: "-6px",
    cursor: "pointer",
  },
  pendingImg: {
    height: "150px",
    textAlign: "center",
    color: theme.palette.get("primary"),
    padding: "20px 0px",
    boxSizing: "border-box",
    fontSize: "4rem",
  },
  img: {
    maxWidth: "100%",
  },
  primary: {
    color: theme.palette.get("primary"),
  },
  badge: {
    padding: "2px 8px",
    display: "inline-block",
    borderRadius: "4px",
  },
  badgePrimary: {
    backgroundColor: theme.palette.get("primary"),
    color: "#fff",
  },
  badgeSuccess: {
    backgroundColor: theme.palette.get("success"),
    color: "#fff",
  },
  badgeWarning: {
    backgroundColor: theme.palette.get("warning"),
    color: "#fff",
  },
  badgeDanger: {
    backgroundColor: theme.palette.get("danger"),
    color: "#fff",
  },
  grey: {
    color: theme.palette.get("grey"),
    fontSize: "0.8rem",
  },
  blackBox: {
    backgroundColor: "#000",
    color: "#fff",
    margin: "0px -16px -16px -16px",
    padding: "16px",
  },
  artistHeadline: {
    marginTop: "0px",
  },
  artistPortraitIcon: {
    backgroundColor: theme.palette.get("default"),
    color: theme.palette.get("grey"),
    textAlign: "center",
    fontSize: "6rem",
    padding: "16px",
    borderRadius: "100%",
  },
  actions: {
    position: "absolute",
    top: "8px",
    left: "0px",
    right: "0px",
    [theme.breakpoints.down("lg")]: {
      top: "0px",
    },
  },
  actionsPlaceholder: {
    height: (props: IProps) => (props.mockupStatus === "none" ? "56px" : "0px"),
    [theme.breakpoints.down("lg")]: {
      height: "46px",
    },
  },
});

interface IImageProps {
  type: "mockup" | "thumb";
  status: "ok" | "pending" | "none";
  src?: string | null;
}

function Image(props: IImageProps) {
  const classes = useClasses(styles, props);
  const { type, src, status } = props;

  if (status === "ok" && typeof src === "string")
    return <img className={classes.img} alt={type} src={src} />;
  if (status === "pending")
    return (
      <div className={classes.pendingImg}>
        <FontAwesomeIcon icon={faSpinnerThird} spin />
      </div>
    );
  return null;
}

function ArtworkDetails(props: IProps) {
  const classes = useClasses(styles, props);
  const [wishlist, addWishlist, deleteWishlist] = props.wishlist;

  useEffect(() => {
    window.history.pushState({}, "", "/artworks/" + props.id);
    return () => {
      window.history.pushState({}, "", "/");
    };
  });
  const isInWishlist = getWishlistElement(wishlist, "/artworks/" + props.id);
  const available = props.available;

  return (
    <Lightbox>
      <div className={classes.alignment}>
        <Row spacing={16} vertical root={0}>
          <Block>
            <div className={classes.wrapper}>
              <div className={classes.actions}>
                <Row spacing={8} root={0}>
                  {Boolean(window.localStorage) && !isInWishlist && (
                    <Button
                      xs="40px"
                      onClick={() => {
                        addWishlist({
                          id: props.id || 0,
                          title: props.title || "",
                          artist: props.artist || "",
                          url: window.location.pathname,
                          img: props.thumbUrl || "",
                          size: `${props.size}cm`,
                        });
                      }}
                    >
                      <FontAwesomeIcon
                        className={classes.heart}
                        icon={faHeart}
                      />
                    </Button>
                  )}
                  {Boolean(window.localStorage) && isInWishlist && (
                    <Button
                      xs="40px"
                      primary
                      onClick={() => {
                        deleteWishlist({
                          id: props.id || 0,
                          title: props.title || "",
                          artist: props.artist || "",
                          url: window.location.pathname,
                          img: props.thumbUrl || "",
                          size: `${props.size}cm`,
                        });
                      }}
                    >
                      <FontAwesomeIcon
                        className={classes.heart}
                        icon={faHeart}
                      />
                    </Button>
                  )}
                  <Col xs="rest">
                    {navigator && navigator?.share && (
                      <Button
                        width="32px"
                        onClick={() => {
                          navigator
                            .share({
                              title: "Künstler: " + props.artist,
                              url:
                                "https://dev.artcare.at/artworks/" + props.id,
                            })
                            .then(() => console.log("Successful share"))
                            .catch(() => console.log("Error sharing"));
                        }}
                      >
                        <FontAwesomeIcon icon={faShareAlt} />
                      </Button>
                    )}
                  </Col>
                  <Button xs="40px" danger onClick={props.onClose}>
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
                </Row>
              </div>
              <div className={classes.actionsPlaceholder} />
              <Row spacing={16} vertical root={0} verticalAlign="bottom">
                {props.mockup !== null && (
                  <Col lg={2}>
                    <Image
                      type="mockup"
                      status={props.mockupStatus}
                      src={props.mockup}
                    />
                  </Col>
                )}
                <Col lg="auto">
                  <Image
                    type="thumb"
                    status={props.thumbStatus}
                    src={props.thumb}
                  />
                </Col>
                <Col lg="rest-fixed">
                  <Row
                    spacing={8}
                    vertical
                    root={{ top: 8, bottom: 0, left: 0, right: 0 }}
                  >
                    <Col xs={10}>
                      <div className={classes.primary}>{props.artist}</div>
                      <div className={classes.grey}>{props.title}</div>
                    </Col>
                    {props.size && (
                      <Col sm={5}>
                        <div className={classes.grey}>{props.size}cm</div>
                      </Col>
                    )}
                    {props.year && (
                      <Col sm={5}>
                        <div className={classes.grey}>{props.year}</div>
                      </Col>
                    )}
                    {props.category && (
                      <Col sm={5}>
                        <div className={classes.grey}>{props.category}</div>
                      </Col>
                    )}
                    {props.technique && (
                      <Col sm={5}>
                        <div className={classes.grey}>{props.technique}</div>
                      </Col>
                    )}
                    {available === 0 && (
                      <span
                        className={classnames([
                          classes.badge,
                          classes.badgeDanger,
                        ])}
                      >
                        Verkauft
                      </span>
                    )}
                    {available === 1 && (
                      <span
                        className={classnames([
                          classes.badge,
                          classes.badgeWarning,
                        ])}
                      >
                        Auf Anfrage
                      </span>
                    )}
                    {available === 2 && Boolean(props.price) && (
                      <span
                        className={classnames([
                          classes.badge,
                          classes.badgeSuccess,
                        ])}
                      >
                        {props.price}€
                      </span>
                    )}
                    {props.description && (
                      <Col>
                        {props.description.split("<br>").map((item, index) => (
                          <>
                            {item}
                            <br />
                          </>
                        ))}
                      </Col>
                    )}

                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <Row
                        spacing={8}
                        vertical
                        root={{ top: 16, left: 0, right: 0, bottom: 0 }}
                        horizontalAlign="right"
                      >
                        <Button xs="110px" onClick={props.onClose}>
                          Schließen
                        </Button>

                        {/*                         {props.available === 2 && Boolean(props.price) && (
                          <Button
                            xs="90px"
                            success
                            onClick={() => {
                              window.location.href = `/artworks/buy/${props.id}`;
                            }}
                          >
                            Kaufen
                          </Button>
                        )} */}
                      </Row>
                    </Form>
                  </Row>
                </Col>
                <div className={classes.blackBox}>
                  <Row spacing={16} vertical root={0}>
                    <Col xs="hidden" sm="hidden" md="hidden" lg={2}>
                      <div className={classes.artistPortraitIcon}>
                        <FontAwesomeIcon icon={faTheaterMasks} />
                      </div>
                    </Col>
                    <Col lg={8}>
                      <h4 className={classes.artistHeadline}>{props.artist}</h4>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: props.artistDescription || "",
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              </Row>
            </div>
          </Block>
        </Row>
      </div>
    </Lightbox>
  );
}

export default ArtworkDetails;
