import React, { useEffect } from "react";
import {
  useClasses,
  ITheme,
  Container,
  Row,
  Col,
  Form,
  TextInput,
  Radio,
  RadioItem,
  Button,
  Checkbox,
} from "@maxeb/admin-ui";
import { getArtworks, useArtworks } from "../containers/Artworks";
import { getActive } from "./Artworks";
import Block from "./Shared/Block";
import Headline from "./Shared/Headline";
import WizzardHead from "./Shared/WizzardHead";
import classnames from "classnames";
import { ICheckout, saveCheckout, useCheckout } from "../containers/Checkout";
import PendingBlock from "./Shared/PendingBlock";
import ErrorBlock from "./Shared/ErrorBlock";
import StateHandler from "./Shared/StateHandler";
import Footer from "./Shared/Footer";

export interface IProps {
  id?: number;
}

const styles = (theme: ITheme) => ({
  checkboxLabel: {
    margin: "8px 0px 0px 0px",
  },
  error: {
    color: theme.palette.get("danger"),
    paddingTop: "4px",
  },
  agbsHaveError: {
    color: theme.palette.get("danger"),
  },
  textWrapper: { position: "relative", height: "2.3rem" },
  subTextWrapper: {
    position: "relative",
  },
  title: {
    color: theme.palette.get("grey"),
    fontSize: "0.8rem",
    fontWeight: 100,
    top: "16px",
  },
  ellipsis: {
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "100%",
    textOverflow: "ellipsis",
    position: "absolute",
  },
  alignRight: {
    textAlign: "right",
  },
  price: {
    fontSize: "1.5rem",
    borderTop: "1px dashed " + theme.palette.get("grey"),
    marginTop: "16px",
    textAlign: "right",
  },
});

export default function Buy(props: IProps) {
  const classes = useClasses(styles);
  const [checkout, setCheckout] = useCheckout();
  const { values, errors } = checkout;
  const [artworks, setArtworks] = useArtworks();
  const active: number | null = getActive("/artworks/buy");

  useEffect(() => {
    if (artworks.status === "init") {
      getArtworks(artworks, setArtworks, active, {});
    }
  });
  const artwork = artworks.data[0];

  function setValue(field: keyof ICheckout["values"]) {
    return (value: string | undefined) => {
      const newState = {
        ...checkout,
        values: { ...checkout.values, [field]: value },
      };
      setCheckout(newState);
    };
  }

  function setBoolValue(field: "agbs" | "dsgvo") {
    return (value: boolean) => {
      const newState = {
        ...checkout,
        values: { ...checkout.values, [field]: value },
      };
      setCheckout(newState);
    };
  }

  delete errors.artwork;

  if (artwork && artwork.available !== 2)
    return <ErrorBlock text="Das Werk ist leider momentan nicht Verfügbar." />;

  return (
    <>
      <Container>
        {artworks.status === "pending" && (
          <PendingBlock text="Werk wird geladen..." />
        )}
        {artworks.status === "error" && (
          <ErrorBlock text="Ein unbekannter Fehler ist aufgetreten, bitte versuchen Sie es später erneut!" />
        )}
        {Boolean(artwork) && active === artwork.id && (
          <Block>
            <WizzardHead active={1} />
            <StateHandler status={checkout.status} errors={errors}>
              <Form
                vertical
                spacing={16}
                root={0}
                onSubmit={(e) => e.preventDefault()}
              >
                <Radio
                  label="Für"
                  value={values.isCompany}
                  onChange={setValue("isCompany")}
                >
                  <RadioItem lg={5} label="Person" value="person" />
                  <RadioItem lg={5} label="Firma" value="company" />
                </Radio>
                {values.isCompany === "person" && (
                  <TextInput
                    inline
                    lg={5}
                    label="Vorname"
                    value={values.firstname}
                    onChange={setValue("firstname")}
                    error={!values.firstname && errors.name}
                  />
                )}
                {values.isCompany === "person" && (
                  <TextInput
                    inline
                    lg={5}
                    label="Nachname"
                    value={values.lastname}
                    onChange={setValue("lastname")}
                    error={!values.lastname && errors.name}
                  />
                )}
                {values.isCompany === "company" && (
                  <TextInput
                    inline
                    label="Firmenname"
                    value={values.lastname}
                    onChange={setValue("lastname")}
                    error={!values.lastname && errors.name}
                  />
                )}
                <TextInput
                  inline
                  lg={5}
                  label="Email"
                  value={values.email}
                  onChange={setValue("email")}
                  error={errors.email}
                />
                <TextInput
                  inline
                  lg={5}
                  label="Telefon"
                  value={values.phone}
                  onChange={setValue("phone")}
                  error={errors.phone}
                />
                <TextInput
                  inline
                  lg={5}
                  label="Adresse"
                  value={values.address}
                  onChange={setValue("address")}
                  error={errors.address}
                />
                <TextInput
                  inline
                  lg={2.5}
                  label="PLZ"
                  value={values.plz}
                  onChange={setValue("plz")}
                  error={errors.plz}
                />
                <TextInput
                  inline
                  lg={2.5}
                  label="Ort"
                  value={values.city}
                  onChange={setValue("city")}
                  error={errors.city}
                />
                <Row
                  spacing={16}
                  vertical
                  root={{ left: 0, right: 0, bottom: 0 }}
                >
                  <Col md={5}>
                    <Headline noMargin variant="miniheadline">
                      Abholung
                    </Headline>
                    <p style={{ marginBottom: 0 }}>
                      Nach erfolgter Zahlung werden wir uns zur Vereinbarung
                      Ihres Abholtermines bei Ihnen melden. Gerne bieten wir
                      Ihnen auch Rahmung Lieferung und Hängung an.
                    </p>
                    <Row
                      spacing={0}
                      vertical
                      root={{ top: 16, left: 0, right: 0, bottom: 0 }}
                    >
                      <Col>
                        <Headline noMargin variant="miniheadline">
                          Vereinbarungen
                        </Headline>
                      </Col>
                      <Checkbox
                        inline
                        xs="auto"
                        value={values.agbs}
                        error={errors.agbs}
                        onChange={setBoolValue("agbs")}
                      >
                        Ich akzeptiere die geltenden{" "}
                        <a
                          target="_blank"
                          href="/assets/documents/Datenschutzerklaerung.pdf"
                        >
                          AGBs
                        </a>
                        .
                      </Checkbox>
                    </Row>
                    <Row spacing={0} vertical root={0}>
                      <Checkbox
                        inline
                        xs="auto"
                        value={values.dsgvo}
                        error={errors.dsgvo}
                        onChange={setBoolValue("dsgvo")}
                      >
                        Ich habe die{" "}
                        <a
                          target="_blank"
                          href="/assets/documents/Datenschutzerklaerung.pdf"
                        >
                          Datenschutzbestimmungen
                        </a>{" "}
                        gelesen und stimme ihnen zu.
                      </Checkbox>
                    </Row>
                  </Col>
                  <Col md={5}>
                    <Headline noMargin variant="miniheadline">
                      Mein Werk
                    </Headline>
                    <Row
                      spacing={8}
                      root={{ top: 8, left: 0, right: 0, bottom: 0 }}
                      verticalAlign="bottom"
                    >
                      <Col xs="100px">
                        <img
                          alt="artwork-preview"
                          style={{ maxHeight: "135px", display: "block" }}
                          src={`https://artware2.datenbanker.at/api/picture/public/${artwork.originalThumbId}/lg/0/${artwork.originalThumbName}.${artwork.originalThumbExtension}`}
                        />
                      </Col>
                      <Col xs="rest-fixed">
                        <div className={classes.textWrapper}>
                          <Row spacing={4} vertical root={0}>
                            <Col>
                              <span
                                className={classnames([
                                  classes.ellipsis,
                                  classes.artist,
                                ])}
                              >
                                {artwork.name}
                              </span>
                            </Col>
                            <Col xs="rest-fixed">
                              <div className={classes.subTextWrapper}>
                                <span
                                  className={classnames([
                                    classes.ellipsis,
                                    classes.title,
                                  ])}
                                >
                                  {artwork.title}
                                </span>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <Col>
                        <div className={classes.price}>
                          <b>{artwork.price?.toLocaleString()}€</b>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row
                  spacing={16}
                  vertical
                  root={{ bottom: 0, left: 0, right: 0 }}
                  horizontalAlign="right"
                >
                  {" "}
                  <Button
                    xs="150px"
                    onClick={() => {
                      window.location.href = `/artworks/${artwork.id}`;
                      console.log(props.id);
                    }}
                  >
                    Zurück
                  </Button>
                  <Button
                    primary
                    xs="150px"
                    onClick={() => {
                      saveCheckout(checkout, setCheckout, artwork.id);
                    }}
                  >
                    Weiter
                  </Button>
                </Row>
              </Form>
            </StateHandler>
          </Block>
        )}
      </Container>
      <Footer />
    </>
  );
}
