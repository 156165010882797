import { request, domain } from "./Abstract";
import { useState } from "react";

export interface IArtist {
  id: number;
  name: string;
}

export interface IArtists {
  status: "init" | "pending" | "success" | "ok" | "error";
  data: IArtist[];
}

export type TSetArtists = (artist: IArtists) => void;

export function useArtists(): [IArtists, TSetArtists] {
  const [state, setState] = useState<IArtists>({ status: "init", data: [] });
  return [state, setState];
}

export async function getArtists(artists: IArtists, setArtists: TSetArtists) {
  try {
    setArtists({ ...artists, status: "pending" });

    const result = await request(
      "GET",
      domain + "/api/artist/all/active",
      {},
      {},
      {}
    );
    const response = JSON.parse(result.response);
    const data = response.map((item: any) => {
      return { id: item.data.id, name: item.data.name };
    });
    const newState: IArtists = {
      ...artists,
      status: "ok",
      data,
    };
    setArtists(newState);
  } catch (error) {
    setArtists({ ...artists, status: "error" });
  }
}
