import React, { useState } from "react";
import {
  useClasses,
  ITheme,
  Row,
  Form,
  TextInput,
  NumberInput,
  Button,
  Checkbox,
} from "@maxeb/admin-ui";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { faHourglassHalf } from "@fortawesome/pro-duotone-svg-icons";

export interface IProps {
  id: number;
  lot: number;
  onClose: () => void;
}
export interface IState {
  name?: string;
  email?: string;
  bid?: number;
  agb: boolean;
  dsgvo: boolean;
}
export interface IErrors {
  name?: string;
  email?: string;
  bid?: string;
  agb?: string;
  dsgvo?: string;
}
const style = (theme: ITheme) => ({
  wrapper: { position: "relative" },
  pending: {
    position: "absolute",
    backgroundColor: "rgba(255, 255, 255, 1)",
    zIndex: 5,
    width: "100%",
    height: "100%",
    color: theme.palette.get("primary"),
    textAlign: "center",
    fontSize: "4rem",
    boxSizing: "border-box",
    paddingTop: "20px",
  },
  success: {
    position: "absolute",
    backgroundColor: "rgba(255, 255, 255, 1)",
    zIndex: 5,
    width: "100%",
    height: "calc(100% - 48px)",
    color: theme.palette.get("success"),
    textAlign: "center",
    fontSize: "4rem",
    boxSizing: "border-box",
    paddingTop: "20px",
  },
  primary: {
    position: "absolute",
    backgroundColor: "rgba(255, 255, 255, 1)",
    zIndex: 5,
    width: "100%",
    height: "calc(100% - 48px)",
    color: theme.palette.get("primary"),
    textAlign: "center",
    fontSize: "4rem",
    boxSizing: "border-box",
    paddingTop: "20px",
  },
  error: {
    position: "absolute",
    backgroundColor: "rgba(255, 255, 255, 1)",
    zIndex: 5,
    width: "100%",
    height: "calc(100% - 48px)",
    color: theme.palette.get("danger"),
    textAlign: "center",
    fontSize: "4rem",
    boxSizing: "border-box",
    paddingTop: "10px",
  },
  errorText: {
    fontSize: "1rem",
    margin: 0,
  },
  agbsError: {
    margin: "0px 0px 0px 0px",
    color: theme.palette.get("danger"),
  },
  checkboxText: {
    margin: "8px 0px 16px 0px",
  },
  info: {
    fontSize: "0.8rem",
    color: " #000",
  },
  legal: {
    fontSize: "0.8rem",
  },
});

const sendContact = async (
  artwork: number,
  values: IState,
  setErrors: (errors: IErrors) => void,
  setState: (state: string) => void
) => {
  try {
    setState("pending");
    const result = await Axios.post(
      "https://artware2.datenbanker.at/api/confirmation/add",
      {
        name: values.name,
        bid: values.bid,
        email: values.email,
        artwork,
      }
    );
    setState("ok");
    if (result.data.error) setErrors(result.data.errors);
    else setState("success");
  } catch (error) {
    setState("error");
  }
};

export default function Bid(props: IProps) {
  const classes = useClasses(style, { ...props });
  const [state, setState] = useState("ok");
  const [values, setValues] = useState<IState>({
    name: "",
    email: "",
    agb: false,
    dsgvo: false,
  });
  const [errors, setErrors] = useState<IErrors>({});

  return (
    <div className={classes.wrapper}>
      {state === "pending" && (
        <div className={classes.pending}>
          <FontAwesomeIcon icon={faSpinnerThird} spin />
        </div>
      )}
      {state === "success" && (
        <div className={classes.primary}>
          <FontAwesomeIcon icon={faHourglassHalf} />
          <p className={classes.info}>
            Ein E-mail mit einem Bestätigungs-Link wurde Ihnen zugesandt. Erst
            nach der Bestätigung ist Ihr Gebot gültig.
          </p>
        </div>
      )}
      {state === "error" && (
        <div className={classes.error}>
          <FontAwesomeIcon icon={faExclamationTriangle} />
          <p className={classes.errorText}>
            Ein unbekannter Fehler ist aufgetreten.
          </p>
        </div>
      )}
      <Form vertical spacing={8} root={0} onSubmit={() => {}}>
        <TextInput
          label="Name"
          value={values.name}
          error={errors.name}
          onChange={(value) => setValues({ ...values, name: value })}
          inline
        />
        <TextInput
          label="Email"
          value={values.email}
          error={errors.email}
          onChange={(value) => setValues({ ...values, email: value })}
          inline
        />
        <NumberInput
          label="Maximal Gebot"
          value={values.bid}
          error={errors.bid}
          onChange={(value) => setValues({ ...values, bid: value })}
          inline
        />
        <span className={classes.legal}>
          Zum Hammerpreis werden ein Aufgeld in Höhe von 25% und die USt. (5%)
          hinzugerechnet.
          <br />
          Personen, die den Zuschlag erhalten, müssen uns vor Abwicklung des
          Geschäfts eine Kopie eines gültigen Lichtbildausweises übermitteln.
        </span>

        <Checkbox
          inline
          xs="auto"
          value={values.agb}
          error={errors.agb}
          onChange={(value) => {
            setValues({ ...values, agb: value });
          }}
        >
          Ich akzeptiere die{" "}
          <a
            target="__blank"
            rel="noreferrer"
            href="/docs/Auktionsbedingungen.pdf"
          >
            Auktionsbedingungen
          </a>
          .
        </Checkbox>
        <Row spacing={0} vertical root={{ left: 0, right: 0, top: 0 }}>
          <Checkbox
            inline
            xs="auto"
            value={values.dsgvo}
            error={errors.dsgvo}
            onChange={(value) => {
              setValues({ ...values, dsgvo: value });
            }}
          >
            Ich akzeptiere die{" "}
            <a target="__blank" href="/docs/Datenschutzerklärung.pdf">
              Datenschutzerklärung
            </a>
            .
          </Checkbox>
        </Row>
        <Row
          spacing={16}
          vertical
          root={{ left: 0, right: 0, bottom: -16 }}
          horizontalAlign="right"
        >
          <Button
            xs="120px"
            onClick={() => {
              props.onClose();
            }}
          >
            {state === "success" ? "Schließen" : "Abbrechen"}
          </Button>
          {state !== "success" && state !== "error" && (
            <Button
              xs="120px"
              primary
              onClick={() => {
                const newErrors: { [key: string]: string } = {};
                if (!values.name) newErrors.name = "Ist ein Pflichtfeld.";
                if (!values.email) newErrors.email = "Ist ein Pflichtfeld.";
                if (!values.bid) newErrors.bid = "Ist ein Pflichtfeld.";
                if (!values.agb)
                  newErrors.agb = "Bitte akzeptieren Sie die AGBS.";
                if (!values.dsgvo)
                  newErrors.dsgvo = "Bitte akzeptieren Sie die DSGVO.";
                if (Object.keys(newErrors).length === 0)
                  sendContact(props.id, values, setErrors, setState);
                else setErrors(newErrors);
              }}
            >
              Abschicken
            </Button>
          )}
        </Row>
        <div />
        <div />
      </Form>
    </div>
  );
}
