import React, { useEffect } from "react";
import { useClasses, ITheme, Container } from "@maxeb/admin-ui";

export interface IProps {
  children: React.ReactNode;
  onClose?: () => void;
}

const styles = (theme: ITheme) => ({
  shadow: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.8)",
    zIndex: 8,
    boxSizing: "border-box",
    overflowY: "scroll",
    padding: "66px 0px 0px 0px",
    "-ms-overflow-style": "none,",
    scrollbars: "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
});

function Lightbox(props: IProps) {
  const classes = useClasses(styles, props);
  useEffect(() => {
    const scrolbarWidth =
      window.innerWidth - document.documentElement.offsetWidth;
    document.body.style.overflow = "hidden";
    document.body.style.paddingRight = `${scrolbarWidth}px`;
    /* window.onpopstate = () => {
      //onClose();
    }; */

    return () => {
      window.onpopstate = null;
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    };
  }, []);
  return (
    <div
      className={classes.shadow}
      onClick={() => {
        if (props.onClose) props.onClose();
      }}
    >
      <div className={classes.box}>
        <Container>{props.children}</Container>
      </div>
    </div>
  );
}

export default Lightbox;
