import {
  Container,
  Col,
  Form,
  Button,
  TextInput,
  StateHandler,
} from "@maxeb/admin-ui";
import {
  IWishlist,
  TAddWishlistElement,
  TDeleteWishlistElement,
  TWishlist,
} from "../containers/Wishlist";
import Headline from "./Shared/Headline";

import ItemLimiter from "./Shared/ItemLimiter";
import WishlistElement from "./WishlistElement";
import WishlistEmpty from "./WishlistEmpty";

import { Wishlist as WishlistApi } from "@maxeb/art-sdk";
import Block from "./Shared/Block";
import { useState } from "react";

interface IProps {
  wishlist: TWishlist;
}

interface IState {
  status: "ok" | "pending" | "error" | "success";
  values: { firstname: string; lastname: string; email: string };
  errors: { [key: string]: string };
}

function Wishlist(props: IProps) {
  const [wishlist, , , , clear] = props.wishlist;

  const [state, setState] = useState<IState>({
    status: "ok",
    values: {
      firstname: "",
      lastname: "",
      email: "",
    },
    errors: {},
  });

  function translateError(error?: string) {
    if (!error) return;

    if (error === "notSet") return "Pflichtfeld!";
    if (error === "notEmail") return "Keine gültige Email!";
    return "Unbekannter Fehler";
  }

  return (
    <div style={{ paddingTop: "16px" }}>
      <Container>
        {wishlist.data.length === 0 && <WishlistEmpty />}
        {wishlist.data.length > 0 && (
          <Block>
            <StateHandler state={state.status}>
              <Form
                spacing={16}
                vertical
                root={{ top: 0, bottom: 0, left: 0, right: 0 }}
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                horizontalAlign="right"
              >
                <Col>
                  <Headline variant="bodyheadline" noMargin>
                    FAVORITEN
                  </Headline>
                </Col>
                <TextInput
                  inline
                  md={5}
                  label="Vorname"
                  value={state.values.firstname}
                  onChange={(value) =>
                    setState({
                      ...state,
                      values: { ...state.values, firstname: value || "" },
                    })
                  }
                  error={translateError(state.errors.firstname)}
                />
                <TextInput
                  md={5}
                  inline
                  label="Nachname"
                  value={state.values.lastname}
                  onChange={(value) =>
                    setState({
                      ...state,
                      values: { ...state.values, lastname: value || "" },
                    })
                  }
                  error={translateError(state.errors.lastname)}
                />
                <TextInput
                  inline
                  md={5}
                  label="Email"
                  value={state.values.email}
                  onChange={(value) =>
                    setState({
                      ...state,
                      values: { ...state.values, email: value || "" },
                    })
                  }
                  error={translateError(state.errors.email)}
                />
                <Col sm="hidden-down" md={5} />
                <Col>
                  <p>
                    Senden Sie uns ihre Werke um mit uns Kontakt aufzunehmen.
                    Wir melden uns bei ihnen unter der angegebenen Adresse.
                  </p>
                </Col>
                <Button
                  xs="120px"
                  primary
                  onClick={async () => {
                    setState({ ...state, status: "pending" });

                    try {
                      const result = await WishlistApi.send({
                        firstname: state.values.firstname,
                        lastname: state.values.lastname,
                        email: state.values.email,
                        id: wishlist.data.map((item) => item.id),
                      });

                      if (result.isSuccess()) {
                        setState({ ...state, status: "success" });
                        setTimeout(clear, 1000);
                      } else {
                        const errors = result.getErrors({
                          "body.firstname": "firstname",
                          "body.lastname": "lastname",
                          "body.email": "email",
                        });

                        setState({ ...state, status: "ok", errors });
                      }
                    } catch (error) {
                      console.log(error);
                      setState({ ...state, status: "error" });
                    }
                  }}
                >
                  Abschicken
                </Button>
              </Form>
            </StateHandler>
          </Block>
        )}
      </Container>
      <ItemLimiter
        spacing={16}
        vertical
        root={{ top: 0, bottom: 0, left: 0, right: 0 }}
        pageSize={24}
        height="calc(100vh - 129px)"
      >
        {wishlist.data.map((element) => (
          <Col
            key={`wishlistelement-${element.url}`}
            sm={5}
            md={10 / 3}
            lg={2.5}
          >
            <WishlistElement
              id={element.id}
              wishlist={props.wishlist}
              url={element.url}
              name={element.artist}
              title={element.title}
              thumb={element.img}
              size={element.size}
            />
          </Col>
        ))}
      </ItemLimiter>
    </div>
  );
}

export default Wishlist;
