import React from "react";
import { Container, Row, Col } from "@maxeb/admin-ui";
import Artwork, { IProps as IArtwork } from "./Artwork";
import PendingBlock from "../Shared/PendingBlock";
import ErrorBlock from "../Shared/ErrorBlock";
import Menu from "./Menu";

import Headline from "../Shared/Headline";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import Footer from "../Shared/Footer";

export const categories = [
  "Gemälde",
  "Grafik Multiple",
  "Keramiken",
  "Multimedia",
  "Skulptur Volumen",
  "Zeichnung/Aquarell",
  "Antiquitäten",
  "Architektonische Elemente",
  "Design",
  "Fotografie",
  "Gegenstände",
  "Keramiken-Glas",
  "Lampen",
  "Miniatur",
  "Mobiliar",
  "Performing Art",
  "Teppiche",
];

export interface IProps {
  id: string;
  lot?: number;
  navigate: NavigateFunction;
}
export interface IState {
  data: IArtwork[];
  pending: boolean;
  unknownError: boolean;
}

export const slots: [number, number | undefined, Date, number][] = [
  [0, 30, new Date(Date.UTC(2021, 11, 18, 20, 0, 0)), 1],
  [30, 40, new Date(Date.UTC(2021, 11, 19, 20, 0, 0)), 2],
  [40, undefined, new Date(Date.UTC(2021, 11, 20, 20, 0, 0)), 3],
];

class Auction extends React.Component<IProps, IState> {
  MOUNT = false;
  constructor(props: IProps) {
    super(props);
    this.state = {
      data: [],
      pending: false,
      unknownError: false,
    };
    this.load = this.load.bind(this);
    this.toggleActive = this.toggleActive.bind(this);
  }
  componentDidMount() {
    this.MOUNT = true;
    this.load();
  }
  componentWillUnmount() {
    this.MOUNT = false;
  }
  getLotAndStartPriceAndArtistContent(text: string) {
    const stringToSplit =
      text.indexOf("#") > -1 && text.indexOf("#") < 50
        ? text.split("#")[0]
        : "0,0";

    const [lot, startPrice, artistContent] = (stringToSplit || "").split(",");

    return [parseInt(lot), parseInt(startPrice), parseInt(artistContent)];
  }
  async load() {
    this.setState({ ...this.state, pending: true });
    try {
      const received = await fetch(
        "https://artware2.datenbanker.at/api/artwork/public/" + this.props.id
      ).then((response) => response.json());
      let data: IState["data"] = received
        .sort((a: any, b: any) => {
          const [lotA] = this.getLotAndStartPriceAndArtistContent(
            a.data.description || ""
          );
          const [lotB] = this.getLotAndStartPriceAndArtistContent(
            b.data.description || ""
          );
          return lotA - lotB;
        })
        .map((item: any) => {
          const [lot, startPrice, artistContent] =
            this.getLotAndStartPriceAndArtistContent(
              item.data.description || ""
            );
          const [, ...description] = item.data.description.split("#");
          //this.props.match.params.id
          const activeLot = 0;

          return {
            id: item.data.id,
            artist: item.data.name,
            title: item.data.title,
            category: categories[item.data.category - 1],
            technique: item.data.techniqueName,
            artistDescription: item.data.artistDescription,
            artistContent,
            description: description.join("#"),
            active: Boolean(activeLot) && parseInt(activeLot || "0") === lot,
            width:
              typeof item.data.width === "string" ||
              typeof item.data.width === "number"
                ? parseInt(item.data.width)
                : undefined,
            height:
              typeof item.data.height === "string" ||
              typeof item.data.height === "number"
                ? parseInt(item.data.height)
                : undefined,
            depth:
              typeof item.data.depth === "string" ||
              typeof item.data.depth === "number"
                ? parseInt(item.data.depth)
                : undefined,
            price: item.data.sellingPrice,
            thumbExtension: item.data.originalThumbExtension,
            thumbHeight: item.data.originalThumbHeight,
            thumbId: item.data.originalThumbId,
            thumbName: item.data.originalThumbName,
            thumbUpdateDate: item.data.originalThumbUpdateDate,
            thumbWidth: item.data.originalThumbWidth,
            year: item.data.year,
            bid: item.data.rentalPrice,
            lot: lot || "-",
            available: item.data.available,
            startPrice,
          };
        });

      if (this.MOUNT) this.setState({ ...this.state, data, pending: false });
    } catch (error) {
      if (this.MOUNT)
        this.setState({ ...this.state, pending: false, unknownError: true });
    }
  }
  toggleActive(lot?: number) {
    if (lot)
      this.props.navigate("/auctions/" + this.props.id + "/artwork/" + lot);
    else {
      this.props.navigate("/auctions/" + this.props.id);
    }

    return;
  }
  renderArtworkFromTo(begin: number, end: number | undefined, time: Date) {
    return (
      <Row spacing={16} vertical root={0}>
        {this.state.data
          .slice(begin, end)
          .map((artwork: IArtwork, index: number) => (
            <Col key={"artwork-" + artwork.id} sm={5} md={10 / 3} lg={10 / 4}>
              <Artwork
                mode="SELLING"
                available={artwork.available}
                active={artwork.lot === this.props.lot}
                id={artwork.id}
                lot={artwork.lot}
                artist={artwork.artist}
                artistContent={artwork.artistContent}
                title={artwork.title}
                category={artwork.category}
                technique={artwork.technique}
                width={artwork.width}
                height={artwork.height}
                depth={artwork.depth}
                thumbExtension={artwork.thumbExtension}
                thumbHeight={artwork.thumbHeight}
                thumbWidth={artwork.thumbWidth}
                thumbId={artwork.thumbId}
                thumbName={artwork.thumbName}
                thumbUpdateDate={artwork.thumbUpdateDate}
                description={artwork.description}
                artistDescription={artwork.artistDescription}
                year={artwork.year}
                price={artwork.price}
                bid={artwork.bid}
                toggle={this.toggleActive}
                startPrice={artwork.startPrice}
                end={time}
              />
            </Col>
          ))}
      </Row>
    );
  }
  render() {
    const { pending, unknownError } = this.state;
    const showContent = !pending && !unknownError;
    const currentTime = new Date();

    return (
      <Row spacing={16} vertical root={{ top: 0, left: 0, right: 0 }}>
        <Menu />
        {pending && <PendingBlock text="Werke werden geladen..." />}
        {unknownError && <ErrorBlock text="Werke werden geladen..." />}
        {showContent &&
          slots
            .sort((a, b) => {
              const [, , timeA] = a;
              const [, , timeB] = b;
              if (currentTime < timeA && currentTime < timeB)
                return +timeA - +timeB;
              else if (currentTime < timeA) return +timeB - +timeA;

              return +timeA;
            })
            .map((slot, index) => {
              const [from, to, time, nr] = slot;
              return (
                <div key={"slot-" + from} id={"slot-" + nr}>
                  <Headline variant="bodyheadline">
                    {currentTime < time && (
                      <span>
                        Verfügbar bis{" "}
                        <span style={{ whiteSpace: "nowrap" }}>
                          {time.toLocaleDateString()}{" "}
                          {time.toLocaleTimeString().substr(0, 5)}
                        </span>
                      </span>
                    )}
                    {currentTime >= time && <span>Abgelaufen</span>}
                  </Headline>
                  {this.renderArtworkFromTo(from, to, time)}
                </div>
              );
            })}
      </Row>
    );
  }
}

function WithRouter() {
  const { id, lot } = useParams();
  const navigate = useNavigate();
  if (id)
    return (
      <>
        <Container>
          <Auction
            id={id}
            lot={typeof lot === "string" ? parseInt(lot) : undefined}
            navigate={navigate}
          />
        </Container>
        <Footer />
      </>
    );
  else
    return (
      <>
        <Container>
          <ErrorBlock />
        </Container>
        <Footer />
      </>
    );
}

export default WithRouter;
