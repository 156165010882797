import React from "react";
import { useClasses, ITheme, Row, Col } from "@maxeb/admin-ui";
import Block from "./Shared/Block";
import classnames from "classnames";
import { faHeart } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { TWishlist } from "../containers/Wishlist";

export interface IProps {
  wishlist: TWishlist;
  id: number;
  name?: string;
  title?: string;
  thumb?: string | null;
  url?: string;
  size?: string;
}

const styles = (theme: ITheme) => ({
  wrapper: { position: "relative", cursor: "pointer" },
  thumb: {
    height: "220px",
    width: "calc(100% + 32px)",
    margin: "-16px -16px 16px -16px",
    backgroundColor: theme.palette.get("default"),
    textAlign: "center",
    paddingTop: "64px",
    boxSizing: "border-box",
    display: "block",
    backgroundPosition: "center",
    backgroundImage: (props: IProps) =>
      props.thumb ? `url("${props.thumb}")` : "none",
  },
  pending: {
    color: theme.palette.get("primary"),
    fontSize: "4rem",
  },
  none: {
    color: theme.palette.get("mediumGrey"),
    fontSize: "6rem",
  },
  textWrapper: { position: "relative", height: "2.3rem" },
  subTextWrapper: {
    position: "relative",
  },
  artist: {
    display: "inline-block",
    fontSize: "1.1rem",
    width: "calc(100% - 26px)",
  },
  title: {
    color: theme.palette.get("grey"),
    fontSize: "0.8rem",
    fontWeight: 100,
    top: "16px",
  },
  ellipsis: {
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "100%",
    textOverflow: "ellipsis",
    position: "absolute",
  },
  alignRight: {
    display: "block",
    textAlign: "right",
  },
  heartBtn: {
    position: "relative",
    top: "-10px",
    fontSize: "1.2rem",
  },
  primary: { color: theme.palette.get("primary") },
});

function WishlistElement(props: IProps) {
  const classes = useClasses(styles, props);
  const [, , deleteFromWishlist] = props.wishlist;
  return (
    <Block>
      <div
        className={classes.wrapper}
        onClick={(e) => {
          const url = `${window.location.origin}${props.url}`;
          window.location.href = url;
        }}
      >
        <div className={classes.thumb} />
        <div className={classes.textWrapper}>
          <Row spacing={8} vertical root={0}>
            <Col>
              <span className={classnames([classes.ellipsis, classes.artist])}>
                {props.name}
              </span>
            </Col>
            <Col xs="rest-fixed">
              <div className={classes.subTextWrapper}>
                <span className={classnames([classes.ellipsis, classes.title])}>
                  {props.title}
                </span>
              </div>
            </Col>
            <Col xs="80px">
              <div className={classes.subTextWrapper}>
                <span
                  className={classnames([
                    classes.alignRight,
                    classes.heartBtn,
                    classes.primary,
                  ])}
                >
                  <FontAwesomeIcon
                    icon={faHeart}
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteFromWishlist({
                        id: props.id,
                        title: props.title || "",
                        artist: props.name || "",
                        url: props.url || "",
                        img: props.thumb || "",
                      });
                    }}
                  />
                </span>
                {Boolean(props.size) && (
                  <span
                    className={classnames([
                      classes.ellipsis,
                      classes.title,
                      classes.alignRight,
                    ])}
                  >
                    {props.size}
                  </span>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Block>
  );
}

export default WishlistElement;
