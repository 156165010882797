import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { faUser } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Row, Col } from "@maxeb/admin-ui";

export interface IProps {
  id: number;
  name?: string;
  description?: string;
  thumbExtension?: string;
  thumbHeight?: number;
  thumbId?: number;
  thumbName?: string;
  thumbUpdateDate?: string;
  thumbWidth?: number;
  center?: boolean;
  portrait?: boolean;
}
export interface IState {
  thumb?: string | null;
  noThumb: boolean;
}

export default class ArtistAtArtwork extends React.Component<IProps, IState> {
  MOUNT = false;
  constructor(props: IProps) {
    super(props);
    this.state = { noThumb: false };
    this.loadImage = this.loadImage.bind(this);
  }
  componentDidMount() {
    this.MOUNT = true;
    this.loadImage("thumb", {
      id: this.props.thumbId,
      updateDate: this.props.thumbUpdateDate,
      extension: this.props.thumbExtension,
      name: this.props.thumbName,
    });
  }
  componentWillUnmount() {
    this.MOUNT = false;
  }
  async loadImage(
    type: "thumb",
    data: {
      id?: number;
      updateDate?: string;
      extension?: string;
      name?: string;
    }
  ) {
    if (
      typeof this.state[type] === "undefined" &&
      data.id &&
      data.extension &&
      data.name &&
      data.updateDate
    ) {
      const img = new Image();
      const src = `https://artware2.datenbanker.at/api/picture/public/${data.id}/lg/0/${data.name}.${data.extension}`;
      img.onload = () => {
        if (this.MOUNT) this.setState({ ...this.state, [type]: src });
      };
      img.onerror = () => {
        if (this.MOUNT)
          this.setState({ ...this.state, [type]: null, noThumb: true });
      };
      img.src = src;
    } else if (typeof this.state[type] !== "string") {
      this.setState({ ...this.state, [type]: null, noThumb: true });
    }
  }
  render() {
    return (
      <div style={{ height: "100%" }}>
        <Row
          spacing={16}
          vertical
          root={{ top: 0, left: 0, right: 0 }}
          verticalAlign="center"
        >
          <Col md="150px">
            {!this.state.thumb && (
              <div
                style={{
                  width: "150px",
                  height: "150px",
                  borderRadius: "100%",
                  boxSizing: "border-box",
                  backgroundColor: "#ececec",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  color: "#246cb5",
                  fontSize: "4rem",
                  textAlign: "center",
                  paddingTop: "25px",
                }}
              >
                {!this.state.noThumb && (
                  <FontAwesomeIcon icon={faSpinnerThird} spin />
                )}
                {this.state.noThumb && (
                  <FontAwesomeIcon
                    icon={faUser}
                    style={{ color: "#a0a0a0", fontSize: "8rem" }}
                  />
                )}
              </div>
            )}
            {Boolean(this.state.thumb) && !this.state.noThumb && (
              <div
                style={{
                  width: "150px",
                  height: "150px",
                  borderRadius: "100%",
                  backgroundColor: "#ececec",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url("${this.state.thumb}")`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              />
            )}
          </Col>
          <Col md="calc(100% - 166px)">
            {this.props.name}
            <div
              style={{ fontSize: "0.8rem", fontWeight: 100 }}
              dangerouslySetInnerHTML={{
                __html: this.props.description || "",
              }}
            ></div>
          </Col>
        </Row>
      </div>
    );
  }
}
