import React from "react";
import { useClasses, ITheme, Block, Row, Button } from "@maxeb/admin-ui";
import { TWishlist } from "../../containers/Wishlist";

export interface IProps {
  wishlist: TWishlist;
}

const style = (theme: ITheme) => ({
  shadow: {
    position: "fixed",
    zIndex: 100,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    padding: "64px 8px 0px 8px",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    [theme.breakpoints.down("md")]: {
      padding: "8px 8px 0px 8px",
    },
  },
  container: {
    maxWidth: "700px !important",
    margin: "auto !important",
    height: "auto !important",
  },
});

export default function WishlistError(props: IProps) {
  const classes = useClasses(style, props);
  const [, , , reset] = props.wishlist;
  return (
    <div
      className={classes.shadow}
      onClick={() => {
        reset();
      }}
    >
      <Block override={{ wrapper: classes.container }}>
        <h1>Lokaler Speicher voll!</h1>
        <p>
          Der lokale Speicher, für diese Website, auf Ihrem Gerät ist voll. Sie
          können keine weiteren Werke zu Ihrer Wunschliste hinzufügen.
          <br />
          Sie können aber gerne diese Abschicken und eine weitere anlegen und
          absenden!
        </p>
        <Row horizontalAlign="right">
          <Button
            xs="100px"
            onClick={() => {
              reset();
            }}
          >
            Close
          </Button>
        </Row>
      </Block>
    </div>
  );
}
