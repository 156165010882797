import React from "react";
import { useClasses, ITheme } from "@maxeb/admin-ui";

export interface IProps {
  stretch?: boolean;
  black?: boolean;
  noPadding?: boolean;
  children: React.ReactNode;
}

const style = (theme: ITheme) => ({
  block: {
    backgroundColor: (props: IProps) => (props.black ? "#000" : "#fff"),
    color: (props: IProps) => (props.black ? "#fff" : "auto"),
    padding: (props: IProps) => (props.noPadding ? "0px" : "16px"),
    boxShadow: "rgba(0, 0, 0, 0.4) 0px 0px 4px 0px",
    boxSizing: "border-box",
    height: (props: IProps) => (props.stretch ? "100%" : "auto"),
  },
});

export default function Block(props: IProps) {
  const classes = useClasses(style, props);
  return <div className={classes.block}>{props.children}</div>;
}
