import React, { useEffect, useState } from "react";
import {
  useClasses,
  ITheme,
  Col,
  Form,
  Select,
  Checkbox,
} from "@maxeb/admin-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLevelUp, faSearch } from "@fortawesome/pro-light-svg-icons";
import { categories } from "../containers/Artworks";
import classNames from "classnames";
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes";
import { IFilters } from "../containers/Artworks";
import { getArtists, useArtists } from "../containers/Artists";

const styles = (theme: ITheme) => ({
  subheader: {
    backgroundColor: "#000",
    color: "#a1a3a6",
    position: "fixed",
    top: "55px",
    borderBottom: "1px solid #3c3c3c",
    fontSize: "1rem",
    zIndex: 9,
    width: "100%",
    left: 0,
    [theme.breakpoints.up("md")]: {
      padding: "8px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "16px",
    },
    boxSizing: "border-box",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "155px",
    },
    [theme.breakpoints.up("xl")]: {
      paddingLeft: "173px",
    },
  },
  placeholder: {
    height: "23px",
  },
  icon: {
    fontSize: "1rem",
    transform: "rotate(90deg)",
  },
  respButton: {
    color: "#a1a3a6",
    fontSize: "1.5rem",
    position: "fixed",
    top: "7px",
    right: "54px",
    zIndex: 11,
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      right: "16px",
    },
  },
  link: {
    color: "#a1a3a6",
    textDecoration: "none",
  },
  active: {
    color: "#fff",
  },
  menuHiddenResp: {
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  //heavy use of !important becasue they are overwrite classes
  input: {
    backgroundColor: "rgba(0, 0, 0, 0) !important",
    height: "22px !important",
    padding: "0px 8px !important",
    [theme.breakpoints.down("lg")]: {
      height: "36px !important",
      border: "1px solid " + theme.palette.get("grey") + "!important",
      padding: "5px 8px 5px 8px !important",
    },
  },
  indicator: {
    marginTop: "6px !important",
    [theme.breakpoints.down("lg")]: {
      marginTop: "12px !important",
    },
  },
  indicatorOpen: {
    marginTop: "6px !important",
    [theme.breakpoints.down("lg")]: {
      marginTop: "12px !important",
    },
  },
  checkboxLabel: {
    padding: "1px 8px 2px 26px !important",
  },
  checkboxInput: {
    margin: "0px !important",
  },
  checkboxIndicator: {
    margin: "0px !important",
    border: "3px solid #a1a3a6 !important",
  },
  checkboxIndicatorActive: {
    margin: "0px !important",
  },
});

export interface IProps {
  filters: IFilters;
  setFilters: (filter: IFilters) => void;
}

function Filter(props: IProps) {
  const classes = useClasses(styles, props);
  const [open, setOpen] = useState(false);
  const [artists, setArtists] = useArtists();

  useEffect(() => {
    if (artists.status === "init") getArtists(artists, setArtists);
    return () => {};
  });

  return (
    <>
      <div className={classes.respButton}>
        <FontAwesomeIcon
          icon={open ? faTimes : faSearch}
          onClick={() => {
            setOpen(!open);
          }}
        />
      </div>
      <div
        className={classNames([classes.placeholder, classes.menuHiddenResp])}
      />
      <div
        className={classNames([
          classes.subheader,
          !open && classes.menuHiddenResp,
        ])}
      >
        <Form
          spacing={16}
          root={0}
          vertical
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Col xs="hidden" sm="hidden" md="hidden" lg="hidden" xl="auto">
            <FontAwesomeIcon className={classes.icon} icon={faLevelUp} />
          </Col>
          <Select
            inline
            lg="160px"
            label="Künstler"
            searchAbel
            searchLabel="Suche"
            noneFound="Niemanden gefunden"
            override={{
              input: classes.input,
              indicator: classes.indicator,
              indicatorOpen: classes.indicatorOpen,
            }}
            value={props.filters.artist}
            options={
              artists.status === "ok"
                ? [
                    { label: "Alle Künstler", value: "all" },
                    ...artists.data.map((item) => ({
                      label: item.name,
                      value: item.id,
                    })),
                  ]
                : [
                    { label: "Alle Künstler", value: "all" },
                    { label: "Lade Künstler...", value: "" },
                  ]
            }
            onChange={(value) => {
              if (value === "all" || typeof value === "number")
                props.setFilters({ ...props.filters, artist: value });
            }}
          />
          <Select
            inline
            lg="180px"
            label="Preis"
            override={{
              input: classes.input,
              indicator: classes.indicator,
              indicatorOpen: classes.indicatorOpen,
            }}
            value={props.filters.price}
            options={[
              { label: "Alle Preise", value: "all" },
              { label: "0€ - 500€", value: 1 },
              { label: "500€ - 1.000€", value: 2 },
              { label: "1.000€ - 2.500€", value: 3 },
              { label: "2.500€ - 5.000€", value: 4 },
              { label: "5.000€ - 10.000€", value: 5 },
              { label: "10.000€ - 50.000€", value: 6 },
              { label: "ab 50.000€", value: 7 },
            ]}
            onChange={(value) => {
              if (value === "all" || typeof value === "number")
                props.setFilters({ ...props.filters, price: value });
            }}
          />
          <Select
            inline
            lg="180px"
            label="Größen"
            override={{
              input: classes.input,
              indicator: classes.indicator,
              indicatorOpen: classes.indicatorOpen,
            }}
            value={props.filters.size}
            options={[
              { label: "Alle Größen", value: "all" },
              { label: "0m - 2m Umfang", value: 1 },
              { label: "2m - 4m Umfang", value: 2 },
              { label: "4m - 8m Umfang", value: 3 },
              { label: "ab 8m Umfang", value: 4 },
            ]}
            onChange={(value) => {
              if (value === "all" || typeof value === "number")
                props.setFilters({ ...props.filters, size: value });
            }}
          />
          <Select
            inline
            lg="180px"
            label="Kategorien"
            override={{
              input: classes.input,
              indicator: classes.indicator,
              indicatorOpen: classes.indicatorOpen,
            }}
            value={props.filters.category}
            options={[
              { label: "Alle Kategorien", value: "all" },
              ...categories.map((item, index) => ({
                label: item,
                value: index + 1,
              })),
            ]}
            onChange={(value) => {
              if (value === "all" || typeof value === "number")
                props.setFilters({ ...props.filters, category: value });
            }}
          />
          <Select
            inline
            lg="150px"
            label="Farben"
            override={{
              input: classes.input,
              indicator: classes.indicator,
              indicatorOpen: classes.indicatorOpen,
            }}
            value={props.filters.color}
            options={[
              { label: "Alle Farben", value: "all" },
              { label: "Weiß", value: "#ffffff" },
              { label: "Rot", value: "#b80e00" },
              { label: "Orange", value: "#db3e00" },
              { label: "Gelb", value: "#fccb00" },
              { label: "Grün", value: "#008b02" },
              { label: "Blau", value: "#004dcf" },
              { label: "Violett", value: "#5300eb" },
              { label: "Schwarz", value: "#000000" },
            ]}
            onChange={(value) => {
              if (typeof value === "string")
                props.setFilters({ ...props.filters, color: value });
            }}
          />
          <Checkbox
            inline
            lg="150px"
            value={props.filters.available}
            onChange={function (checked: boolean): void {
              props.setFilters({ ...props.filters, available: checked });
            }}
            override={{
              label: classes.checkboxLabel,
              input: classes.checkboxInput,
              indicator: props.filters.available
                ? classes.checkboxIndicatorActive
                : classes.checkboxIndicator,
            }}
          >
            Verfügbar
          </Checkbox>
        </Form>
      </div>
    </>
  );
}

export default Filter;
