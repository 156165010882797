import {
  Container,
  Row,
  Col,
  Form,
  TextInput,
  Checkbox,
  Button,
} from "@maxeb/admin-ui";
import { useContact, saveContact } from "../containers/Contact";
import StateHandler from "./Shared/StateHandler";

function ContactRent() {
  const [form, setForm] = useContact();
  const { values, errors } = form;
  const setValue = (field: string) => (value?: string) => {
    const newValues = { ...values, [field]: value };
    setForm({ ...form, values: newValues });
  };
  return (
    <>
      <Container>
        <Row spacing={16} vertical root={0}>
          <Col>
            <StateHandler status={form.status} errors={errors}>
              <p>
                Ich interessiere mich für Kunstmiete. Bitte kontaktieren Sie
                mich.
              </p>
              <Form
                vertical
                root={0}
                spacing={16}
                horizontalAlign="right"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <TextInput
                  label="Telefonnummer"
                  inline
                  md={5}
                  value={values.phone}
                  onChange={setValue("phone")}
                  error={errors.phone}
                />
                <TextInput
                  label="E-Mail"
                  inline
                  md={5}
                  value={values.email}
                  onChange={setValue("email")}
                  error={errors.email}
                />
                <Checkbox
                  inline
                  xs={10}
                  value={values.agbs}
                  error={errors.agbs}
                  onChange={(value) => {
                    const newValues = { ...values, agbs: value };
                    setForm({ ...form, values: newValues });
                  }}
                >
                  Ich habe die{" "}
                  <a
                    target="_blank"
                    href="/assets/documents/Datenschutzerklaerung.pdf"
                  >
                    Datenschutzbestimmungen
                  </a>{" "}
                  gelesen und stimme ihnen zu.
                </Checkbox>
                <Button
                  xs="120px"
                  onClick={() => {
                    saveContact(form, setForm);
                  }}
                  primary
                >
                  Senden
                </Button>
              </Form>
            </StateHandler>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ContactRent;
