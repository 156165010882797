import { Col } from "@maxeb/admin-ui";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getArtworks, useArtworks } from "../containers/Artworks";
import { TWishlist } from "../containers/Wishlist";
import Artwork from "./Artwork";
import Filter from "./Filter";
import ErrorBlock from "./Shared/ErrorBlock";
import ItemLimiter from "./Shared/ItemLimiter";
import PendingBlock from "./Shared/PendingBlock";

export function getActive(start: string): number | null {
  const pathname = window.location.pathname;
  if (pathname.startsWith(start)) {
    const id = pathname.split("/").pop();
    if (id) return parseInt(id);
  }
  return null;
}

export interface IProps {
  wishlist: TWishlist;
}

function Artworks(props: IProps) {
  const [artworks, setArtworks, setFilters] = useArtworks();
  const { filters, filtered } = artworks;

  const params = useParams();

  useEffect(() => {
    if (artworks.status === "init") {
      const active: number | null = getActive("/artworks");
      getArtworks(artworks, setArtworks, active, params, filters);
    }
    if (params.collection !== artworks.collection)
      setArtworks({
        ...artworks,
        status: "init",
        collection: params.collection,
      });
  });

  return (
    <>
      <Filter filters={filters} setFilters={setFilters} />
      {artworks.status !== "ok" && (
        <div style={{ paddingTop: "16px" }}>
          {artworks.status === "pending" && (
            <PendingBlock text="Werke werden geladen..." />
          )}
          {artworks.status === "error" && (
            <ErrorBlock text="Ein unbekannter Fehler ist aufgetreten, bitte versuchen Sie es später erneut!" />
          )}
        </div>
      )}
      {artworks.status === "ok" && (
        <ItemLimiter
          spacing={16}
          vertical
          root={{ top: 0, bottom: 0, left: 0, right: 0 }}
          pageSize={24}
          height={"calc(100vh - 125px)"}
        >
          {filtered.map((artwork, index) => (
            <Col
              key={`${index === 0 && "first"}${artwork.id}`}
              sm={5}
              md={10 / 3}
              lg={2.5}
            >
              <Artwork {...artwork} wishlist={props.wishlist} />
            </Col>
          ))}
        </ItemLimiter>
      )}
    </>
  );
}

export default Artworks;
