import { faHeart } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useClasses, ITheme, Row, Col } from "@maxeb/admin-ui";
import Block from "./Shared/Block";
import Headline from "./Shared/Headline";

const styles = (theme: ITheme) => ({
  iconAlignment: {
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  iconWrapper: {
    backgroundColor: theme.palette.get("default"),
    color: theme.palette.get("grey"),
    display: "inline-block",
    fontSize: "4rem",
    width: "150px",
    height: "150px",
    textAlign: "center",
    borderRadius: "100%",
    paddingTop: "30px",
    boxSizing: "border-box",
  },
  list: { marginTop: 0 },
  listElement: {
    margin: "4px 0px",
  },
  textElement: {
    margin: "0px",
  },
});

function WishlistEmpty() {
  const classes = useClasses(styles);

  return (
    <Block>
      <Row spacing={16} vertical root={0}>
        <Col md="auto">
          <div className={classes.iconAlignment}>
            <div className={classes.iconWrapper}>
              <FontAwesomeIcon icon={faHeart} />
            </div>
          </div>
        </Col>
        <Col md="rest-fixed">
          <Headline variant="h2">Meine Favoriten</Headline>
          <p className={classes.textElement}>Senden Sie uns Ihre Favoriten!</p>
          <p className={classes.textElement}>
            Wir bereiten die Werke gerne für eine persönliche Besichtigung in
            unseren Räumlichkeiten für Sie vor.
          </p>
          <p className={classes.textElement}>
            Aktivieren Sie auf der Werke seite das Herz um ein Liste zu
            erstellen. Senden Sie uns dann diese Liste um in Kontakt zu treten.
          </p>
        </Col>
      </Row>
    </Block>
  );
}

export default WishlistEmpty;
