import React from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Menu from "./components/Shared/Menu";
import About from "./components/About";
import { useClasses, ITheme } from "@maxeb/admin-ui";
import Artworks from "./components/Artworks";
import Rent from "./components/Rent";
import Service from "./components/Service";
import Contact from "./components/Contact";
import Auctions from "./components/Auctions/List";
import Auction from "./components/Auctions/Auction";
import ArchiveAuction from "./components/Auctions/ArchiveAuction";
import Book from "./components/Auctions/Book";
import News from "./components/News";
import Wishlist from "./components/Wishlist";
import Buy from "./components/Buy";
import OrderSuccess from "./components/OrderSuccess";
import OrderError from "./components/OrderError";
import { useWishlist } from "./containers/Wishlist";
import Lightbox from "./components/Shared/Lightbox";
import Block from "./components/Shared/Block";
import WishlistError from "./components/Shared/WishlistError";

const styles = (theme: ITheme) => ({
  main: {
    minHeight: "calc(100vh - 300px)",
    padding: "55px 0px 0px 0px",
  },
});

function App() {
  const classes = useClasses(styles);
  const wishlist = useWishlist();

  return (
    <div className="App">
      {wishlist[0].error && <WishlistError wishlist={wishlist} />}
      <Router>
        <Menu wishlist={wishlist} />
        <main className={classes.main}>
          <Routes>
            <Route path="/about" element={<About />} />
            <Route path="/" element={<Artworks wishlist={wishlist} />} />
            <Route
              path="/wishlist"
              element={<Wishlist wishlist={wishlist} />}
            />
            <Route
              path="/artworks/:id"
              element={<Artworks wishlist={wishlist} />}
            />
            <Route
              path="/collection/:collection/artworks"
              element={<Artworks wishlist={wishlist} />}
            />
            <Route path="/artworks/buy/:id" element={<Buy />} />
            <Route path="/order/success" element={<OrderSuccess />} />
            <Route path="/order/cancel" element={<OrderError />} />
            <Route path="/news" element={<News />} />
            <Route path="/rent" element={<Rent />} />
            <Route path="/service" element={<Service />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/auctions" element={<Auctions />} />
            <Route path="/auctions/:id" element={<Auction />} />
            <Route path="/auctions/:id/book" element={<Book />} />
            <Route path="/auctions/:id" element={<Auction />} />
            <Route path="/auctions/:id/artwork/:lot" element={<Auction />} />
            <Route path="/auctions/archive/:id" element={<ArchiveAuction />} />
          </Routes>
        </main>
      </Router>
    </div>
  );
}

export default App;
