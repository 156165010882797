import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { init } from "@maxeb/admin-ui";
import { options as ArtOptions } from "@maxeb/art-sdk";

ArtOptions.setApiKey("UC7iYHgmy6rUZiLz9q0kTYWDYIsjOpE3");
ArtOptions.setUrl("https://art.webcollector.at");

const palette = {
  primary: "#5c90d2",
  secondary: "#2c689c",
  tertiary: "#212a37",
  danger: "#b7433f",
  success: "#559554",
  warning: "#e8db05",
  default: "#ececec",
  grey: "#e0e0e0",
  darkgrey: "#7d7d7d",
  font: "#3e3e3e",
  background: "#ececec",
  formLabel: "#797979",
  formBackground: "#ececec",
  formBackgroundActive: "#e0e0e0",
  mediumGrey: "#a8a8a8",
  section: "#717171",
};

init({ palette: palette });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
