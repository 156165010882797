import React from "react";
import { useClasses, ITheme } from "@maxeb/admin-ui";
import MenuLarge from "./MenuLarge";
import MenuSmall from "./MenuSmall";
import { IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { TWishlist } from "../../containers/Wishlist";

const styles = (theme: ITheme) => ({
  header: {
    backgroundColor: "#000",
    color: "#a1a3a6",
    fontSize: "1rem",
    letterSpacing: "0.2px",
    fontWeight: 100,
    textDecoration: "none",
    position: "fixed",
    width: "100%",
    borderBottom: "1px solid #3c3c3c",
    zIndex: 10,
  },
  active: {
    color: "#000",
  },
  showBig: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  showSmall: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
});

export type TLinks = {
  label: string | IconDefinition;
  to: string;
  alias?: string[];
}[];

const links = [
  { label: "Werke", to: "/" },
  { label: "Auktionen", to: "/auctions" },
  { label: "News", to: "/news" },
  { label: "Mieten", to: "/rent" },
  { label: "Leistungen", to: "/service" },
  { label: "Kontakt", to: "/contact" },
  { label: "Über Uns", to: "/about" },
];

export interface IProps {
  wishlist: TWishlist;
}

function Menu(props: IProps) {
  const classes = useClasses(styles);

  return (
    <header className={classes.header}>
      <div className={classes.showBig}>
        <MenuLarge wishlist={props.wishlist} links={links} />
      </div>
      <div className={classes.showSmall}>
        <MenuSmall links={links} />
      </div>
    </header>
  );
}

export default Menu;
