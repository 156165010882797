import { useState } from "react";

const wishlistSymbol = "wishlist";

export interface IWishlistElement {
  id: number;
  title: string;
  artist: string;
  url: string;
  img: string;
  size?: string;
}

export interface IWishlist {
  version: number;
  data: IWishlistElement[];
  error: boolean;
}

export type TAddWishlistElement = (element: IWishlistElement) => void;
export type TDeleteWishlistElement = (element: IWishlistElement) => void;
export type TResetWishlistError = () => void;
export type TClear = () => void;

export type TWishlist = [
  IWishlist,
  TAddWishlistElement,
  TDeleteWishlistElement,
  TResetWishlistError,
  TClear
];

function getInitState() {
  const defaultState = {
    version: 2,
    data: [],
    error: false,
  };
  if (!window.localStorage) return defaultState;

  const storageString = window.localStorage.getItem(wishlistSymbol);
  if (storageString === null) return defaultState;

  let storageState: IWishlist = JSON.parse(storageString);
  const notSupportedVersion = storageState.version !== defaultState.version;

  if (notSupportedVersion) {
    window.localStorage.removeItem(wishlistSymbol);
    return defaultState;
  }

  return storageState;
}

export function useWishlist(): TWishlist {
  const [state, setState] = useState<IWishlist>(getInitState());

  return [
    state,
    (element: IWishlistElement) => {
      const newData = [...state.data, element];
      const newState = { ...state, data: newData };
      try {
        if (window.localStorage)
          window.localStorage.setItem(wishlistSymbol, JSON.stringify(newState));
        setState(newState);
      } catch (error) {
        setState({ ...state, error: true });
      }
    },
    (element: IWishlistElement) => {
      const newData = state.data.filter((item) => item.url !== element.url);
      const newState = { ...state, data: newData };
      if (window.localStorage)
        window.localStorage.setItem(wishlistSymbol, JSON.stringify(newState));
      setState(newState);
    },
    () => {
      setState({ ...state, error: false });
    },
    () => {
      if (window.localStorage) {
        console.log("CALLED");
        window.localStorage.removeItem(wishlistSymbol);
        setState({
          version: 2,
          data: [],
          error: false,
        });
      }
    },
  ];
}

export function getWishlistElement(
  state: IWishlist,
  url: string
): IWishlistElement | null {
  return state.data.find((item) => item.url === url) || null;
}
