import { request, domain } from "./Abstract";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export interface IArtwork {
  open: boolean;
  confirmation?: true;
  code?: string;
  id: number;
  category?: string;
  categoryId?: number;
  color1?: string | null;
  color2?: string | null;
  color3?: string | null;
  technique?: string;
  artist?: string;
  name?: string;
  artistContent?: number;
  title?: string;
  artistId: number;
  artistDescription?: string;
  description?: string;
  width?: number;
  height?: number;
  depth?: number;
  extent?: number;
  price?: number;
  originalThumbExtension?: string;
  originalThumbHeight?: number;
  originalThumbWidth?: number;
  originalThumbId?: number;
  originalThumbName?: string;
  originalThumbUpdateDate?: string;
  thumbWidth?: number;
  year?: number;
  available?: number;
}

export interface IFilters {
  available: boolean;
  artist: "all" | number;
  price: "all" | number;
  size: "all" | number;
  category: "all" | number;
  color: string;
}

export interface IArtworks {
  status: "init" | "pending" | "success" | "ok" | "error";
  data: IArtwork[];
  filtered: IArtwork[];
  filters: IFilters;
  collection?: string;
}

export type TSetArtwork = (artworks: IArtworks) => void;
export type TSetFilters = (filters: IFilters) => Promise<void>;

function getAvailable(value: string | null): boolean {
  return value === "true";
}

function getId(value: string | null): "all" | number {
  if (value === null || value === "all") return "all";

  const parsed = parseInt(value);
  if (parsed.toString() !== value) return "all";
  else return parsed;
}

export function useArtworks(): [IArtworks, TSetArtwork, TSetFilters] {
  const [searchParam, setSearchParams] = useSearchParams();

  const [state, setState] = useState<IArtworks>({
    status: "init",
    data: [],
    filters: {
      available: getAvailable(searchParam.get("available")),
      artist: getId(searchParam.get("artist")),
      price: getId(searchParam.get("price")),
      size: getId(searchParam.get("size")),
      category: getId(searchParam.get("category")),
      color: "all",
    },
    filtered: [],
  });

  useEffect(() => {
    setSearchParams(
      Object.entries(state.filters).filter((k, v) => v.toString() !== "all")
    );
  }, [state.filters, setSearchParams]);

  return [
    state,
    (artworks: IArtworks) => {
      if (state.status !== "pending") setState(artworks);
    },
    async (filters: IFilters) => {
      if (state.status !== "pending") {
        const newState: IArtworks = { ...state, status: "pending", filters };
        setState({ ...state, status: "pending", filters });
        applyFilters(newState, filters, setState);
      }
    },
  ];
}

const applyPriceFilter = (value: number | undefined, range: "all" | number) => {
  if (range === "all") return true;
  if (typeof value === "undefined") return false;
  switch (range) {
    case 1:
      return value >= 0 && value <= 500;
    case 2:
      return value >= 500 && value <= 1000;
    case 3:
      return value >= 1000 && value <= 2500;
    case 4:
      return value >= 2500 && value <= 5000;
    case 5:
      return value >= 5000 && value <= 10000;
    case 6:
      return value >= 10000 && value <= 50000;
    case 7:
      return value >= 50000;
  }
};
const applySizeFilter = (value: number | undefined, range: "all" | number) => {
  if (range === "all") return true;
  if (typeof value === "undefined") return false;
  switch (range) {
    case 1:
      return value >= 0 && value <= 200;
    case 2:
      return value >= 200 && value <= 400;
    case 3:
      return value >= 400 && value <= 800;
    case 4:
      return value >= 800;
  }
};
const applyCategoryFilter = (
  value: number | undefined,
  range: "all" | number
) => {
  if (range === "all") return true;
  if (typeof value === "undefined") return false;
  return value === range;
};
const applyColorFilter = (
  color1: string | null | undefined,
  color2: string | null | undefined,
  color3: string | null | undefined,
  range: string
) => {
  if (range === "all") return true;
  if (!color1 || !color2 || !color3) return false;
  const inRange = color1 === range || color2 === range || color3 === range;
  return inRange;
};
const applyArtistFilter = (value: number, range: "all" | number) => {
  if (range === "all") return true;
  const inRange = value === range;
  return inRange;
};
const applyAvailable = (value: number, available: boolean) => {
  if (!available) return true;
  return value > 1;
};

async function filter(
  artworks: IArtworks,
  filters: IFilters
): Promise<IArtworks["data"]> {
  if (
    filters.price !== "all" ||
    filters.size !== "all" ||
    filters.category !== "all" ||
    filters.color !== "all" ||
    filters.artist !== "all" ||
    filters.available
  ) {
    const filtered: IArtworks["data"] = [];
    //chain async filters for non blocking ui
    await artworks.data.reduce(async (promise, artwork) => {
      await promise;
      const inPriceRange = applyPriceFilter(artwork.price, filters.price);
      const inSizeRange = applySizeFilter(artwork.extent, filters.size);
      const inCategory = applyCategoryFilter(
        artwork.categoryId,
        filters.category
      );
      const inArtist = applyArtistFilter(artwork.artistId, filters.artist);
      const inColor = applyColorFilter(
        artwork.color1,
        artwork.color2,
        artwork.color3,
        filters.color
      );
      const isAvailable = applyAvailable(
        artwork.available || 0,
        filters.available
      );

      if (
        inPriceRange &&
        inSizeRange &&
        inCategory &&
        inColor &&
        inArtist &&
        isAvailable
      ) {
        filtered.push(artwork);
      }
    }, Promise.resolve());
    return filtered;
  }
  return artworks.data;
}

export async function applyFilters(
  artworks: IArtworks,
  filters: IFilters,
  setState: React.Dispatch<React.SetStateAction<IArtworks>>
) {
  if (
    filters.price !== "all" ||
    filters.size !== "all" ||
    filters.category !== "all" ||
    filters.color !== "all" ||
    filters.artist !== "all" ||
    filters.available
  ) {
    const filtered = await filter(artworks, filters);
    setState({ ...artworks, filtered, filters, status: "ok" });
    window.scrollTo(0, 0);
  } else
    setState({ ...artworks, filtered: artworks.data, filters, status: "ok" });
}
export const categories = [
  "Gemälde",
  "Grafik Multiple",
  "Keramiken",
  "Multimedia",
  "Skulptur Volumen",
  "Zeichnung/Aquarell",
  "Antiquitäten",
  "Architektonische Elemente",
  "Design",
  "Fotografie",
  "Gegenstände",
  "Keramiken-Glas",
  "Lampen",
  "Miniatur",
  "Mobiliar",
  "Performing Art",
  "Teppiche",
];
function isParsableNumber(value: any): value is string {
  return value && value !== "0.00" && value !== "0";
}
function parseNumberOrDelete(
  object: { [key: string]: any },
  name: string,
  alias?: string
) {
  if (isParsableNumber(object[name]))
    object[alias || name] = parseInt(object[name]);
  else delete object[alias || name];
}
export async function getArtworks(
  artworks: IArtworks,
  setArtworks: TSetArtwork,
  active: number | null,
  params: { collection?: string },
  filters?: IFilters
) {
  try {
    setArtworks({
      ...artworks,
      collection: params.collection,
      status: "pending",
    });

    const result = await request(
      "GET",
      params.collection
        ? domain + "/api/artwork/public/" + params.collection
        : domain + "/api/artwork/public",
      {},
      {},
      {}
    );
    const response = JSON.parse(result.response);
    let found: IArtwork | null = null;

    const data = response.map((item: any) => {
      const toParse = {
        ...item.data,
        technique: item.data.techniqueName,
        artistId: item.data.artist,
        category: categories[item.data.category - 1],
        categoryId: item.data.category,
        open: false,
      };

      parseNumberOrDelete(toParse, "width");
      parseNumberOrDelete(toParse, "height");
      parseNumberOrDelete(toParse, "depth");
      if (toParse.width && toParse.height)
        toParse.extent = (toParse.width + toParse.height) * 2;

      parseNumberOrDelete(toParse, "sellingPrice", "price");
      if (active === toParse.id) found = { ...toParse, open: true };
      return toParse;
    });

    if (found) data.unshift(found);

    const newState: IArtworks = {
      ...artworks,
      status: "ok",
      data,
      filtered: data,
      collection: params.collection,
    };

    if (filters) newState.filtered = await filter(newState, filters);

    setArtworks(newState);
  } catch (error) {
    console.error(error);
    setArtworks({ ...artworks, status: "error" });
  }
}
