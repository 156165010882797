import React from "react";
import { useClasses, ITheme, Row, Col } from "@maxeb/admin-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons/faSpinnerThird";
import Block from "./Block";

export interface IProps {
  text?: string;
}

const style = (theme: ITheme) => ({
  pendingIcon: {
    fontSize: "4rem",
    color: theme.palette.get("primary"),
  },
  pendingBlock: {
    textAlign: "center",
    padding: "46px 0px",
  },
  pendingText: {
    color: theme.palette.get("grey"),
    fontWeight: 100,
  },
});

export default function PendingBlock(props: IProps) {
  const classes = useClasses(style, props);
  return (
    <Row spacing={12} vertical horizontalAlign="center">
      <Col md="300px">
        <Block>
          <div className={classes.pendingBlock}>
            <FontAwesomeIcon
              className={classes.pendingIcon}
              icon={faSpinnerThird}
              spin
            />
            <p className={classes.pendingText}>{props.text}</p>
          </div>
        </Block>
      </Col>
    </Row>
  );
}
