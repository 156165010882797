import { useClasses, ITheme, Container, Row, Col } from "@maxeb/admin-ui";
import classnames from "classnames";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import c1 from "../resources/images/Carousel/Carousel_1.jpg";
import c2 from "../resources/images/Carousel/Carousel_2.jpg";
import c3 from "../resources/images/Carousel/Carousel_3.jpg";
import c4 from "../resources/images/Carousel/Carousel_4.jpg";
import c5 from "../resources/images/Carousel/Carousel_5.jpg";
import c6 from "../resources/images/Carousel/Carousel_6.jpg";
import ContactRent from "./ContactRent";

import Block from "./Shared/Block";
import Footer from "./Shared/Footer";
import Headline from "./Shared/Headline";

const styles = (theme: ITheme) => ({
  table: {
    borderCollapse: "collapse",
  },
  th: {
    padding: "8px",
    border: "1px solid black",
    minWidth: "120px",
    textAlign: "right",
  },
  td: {
    padding: "8px",
    border: "1px solid black",
  },
  trOdd: {
    backgroundColor: "#dde2e4",
  },
});

function Rent() {
  const classes = useClasses(styles);
  return (
    <div style={{ paddingTop: "16px" }}>
      <Container>
        <Row spacing={16} vertical root={0}>
          <Col>
            <Block noPadding>
              <Carousel showArrows={true}>
                <img src={c1} alt="example_1" />
                <img src={c2} alt="example_2" />
                <img src={c3} alt="example_3" />
                <img src={c4} alt="example_4" />
                <img src={c5} alt="example_5" />
                <img src={c6} alt="example_6" />
              </Carousel>
              <div style={{ height: "16px" }} />
            </Block>
          </Col>
          <Col>
            <Block>
              <Row spacing={16} vertical root={0}>
                <Col lg="calc(100% - 300px)">
                  <Headline variant="subheadline">
                    Wählen Sie aus über 4.500 Werken
                  </Headline>
                  <p>Tür auf. Kunst rein. Einfach gemietet.</p>
                  <p>
                    Der schönste Raum wirkt leer ohne Kunst. Bringen Sie Kunst
                    in Ihr Leben und freuen Sie sich auf die positiven
                    Auswirkungen. Kunst verbindet und begleitet uns im Alltag,
                    sie fordert uns heraus und regt uns an, miteinander zu
                    kommunizieren.
                  </p>
                  <p>
                    Wir bieten Ihnen von unseren Kurator*innen ausgewählte
                    Originalwerke, ausgesuchte Druckgrafiken, Fotoarbeiten und
                    Skulpturen.
                  </p>
                  <p>
                    Die Kunstmiete beinhaltet individuelle Beratung und
                    Betreuung, Rahmung, Lieferung, Hängung und Versicherung der
                    Kunstwerke. Außerdem sind die Mieten im Unternehmen als
                    Betriebsausgabe absetzbar. Und das Beste, Sie können mit
                    unserem Tauschservice Ihre Kunstwerke jederzeit austauschen,
                    sollten Sie Lust auf etwas Neues haben.
                  </p>
                </Col>
                <Col lg="245px">
                  <Headline variant="subheadline">
                    Preise <small>exl. USt/Monat</small>
                  </Headline>
                  <table className={classes.table}>
                    <thead>
                      <tr className={classes.row}>
                        <th
                          className={classnames([
                            classes.th,
                            classes.borderRight,
                          ])}
                        >
                          bis Umfang
                        </th>
                        <th className={classes.th}>pro Monat</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className={classes.trOdd}>
                        <td className={classes.td}>2m</td>
                        <td className={classes.td}>40€</td>
                      </tr>
                      <tr className={classes.tr}>
                        <td className={classes.td}>4m</td>
                        <td className={classes.td}>80€</td>
                      </tr>
                      <tr className={classes.trOdd}>
                        <td className={classes.td}>6m</td>
                        <td className={classes.td}>100€</td>
                      </tr>
                      <tr className={classes.tr}>
                        <td className={classes.td}>8m</td>
                        <td className={classes.td}>125€</td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
                <ContactRent />
              </Row>
            </Block>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Rent;
