import logo from "../resources/images/artcare_transparent_s.svg";
import wlogo from "../resources/images/webcollector.svg";
import { ITheme, useClasses, Container, Row, Col } from "@maxeb/admin-ui";
import Block from "./Shared/Block";
import Footer from "./Shared/Footer";
import Headline from "./Shared/Headline";

const styles = (theme: ITheme) => ({
  logo: {
    maxWidth: "100%",
  },
  wlogo: {
    width: "100%",
  },
  logoHolder: {
    textAlign: "center",
    fontWeight: 100,
  },
  wLogoText: {
    fontSize: "1.7rem",
  },
  whiteText: {
    color: "#fff",
  },
  list: {
    margin: 0,
  },
  headline: {
    marginBottom: 8,
  },
  alignCenter: {
    textAlign: "center",
  },
  maxTextWidth: {
    maxWidth: "600px",
    display: "inline-block",
  },
  label: {
    fontWeight: 600,
  },
  blueText: {
    margin: "0px",
    color: theme.palette.get("primary"),
  },
  noBottomMargin: {
    marginBottom: "0px",
  },
  noTextDecoration: {
    textDecoration: "none",
    color: "#3e3e3e;",
  },
});

function About() {
  const classes = useClasses(styles);
  return (
    <div style={{ paddingTop: "16px" }}>
      <Container>
        <Row spacing={16} vertical root={0}>
          <Col stretch md={5}>
            <Block stretch>
              <div className={classes.logoHolder}>
                <img src={logo} alt="Artcare-Logo" className={classes.logo} />
                <p>
                  ARTCARE Marketing und Vertriebs GmbH
                  <br /> RECHTE BAHNGASSE 30-32, 1030 WIEN
                </p>
                <p className={classes.noBottomMargin}>
                  <a
                    className={classes.noTextDecoration}
                    href="mailTo:office@artcare.at"
                  >
                    OFFICE@ARTCARE.AT
                  </a>
                  <br />
                  <a
                    className={classes.noTextDecoration}
                    href="https://artcare.at"
                    target="_blank"
                    rel="noreferrer"
                  >
                    WWW.ARTCARE.AT
                  </a>
                </p>
                <p className={classes.blueText}>
                  FN 287030 d
                  <br />
                  ATU74758924
                </p>
              </div>
            </Block>
          </Col>
          <Col md={5}>
            <Block>
              <Headline variant="subheadline">
                Artcare Kunsthandels- und Archivierungs- Plattform
                <br />
                <small>für junge und zeitgenössische Kunst</small>
              </Headline>
              <p>
                Artcare ist die Plattform für junge und zeitgenössische Kunst
                mit Sitz in Wien. Artcare verfügt über jahrelange Kontakte zu
                den etablierten Wiener Kunsthochschulen, arrivierten und
                aufstrebenden Künstler*innen und Studierenden, sowie zu
                nationalen und internationalen Sammler*innen. Artcare bewegt
                sich vor allem in der jungen Szene, hat aber auch Zugang zu
                renommierten Arbeiten durch die digitale Erfassung namhafter
                Sammlungen. An den Artcare Standorten in Wien, Landstraße und
                Floridsdorf ist besonders die Förderung junger Kunst im Focus.
                Mit der jährlichen YOUNG ART AUCTION wird eine Förderauktion für
                junge Künstler*innen bis 35 veranstaltet.
                <br />
                Im FLORIDSDORF MUSEUM FOR YOUNG & CONTEMPORARY ART (FLOMYCA)
                entsteht eine neue Begegnungszone für Kunstschaffende und
                Kunstinteressierte. Auf 600m2 Fläche entstehen
                Künstler*innenstudios, sowie Ausstellungs- und
                Veranstaltungsräume.
              </p>
              <h4 className={classes.headline}>Artcare am Puls der Kunst</h4>
              <ul className={classes.list}>
                <li>Kunstprojekte & Künstlerateliers</li>
                <li>Kunstberatung & -vermittlung</li>
                <li>Kunsthandel, Auktionen</li>
                <li>Kunstmiete & Staging (Probehängung mit Kaufoption)</li>
                <li>Rahmung, Versicherung, Lieferung, Einlagerung, Hängung</li>
                <li>Kunstarchivierung & Digitalisierung</li>
              </ul>
            </Block>
          </Col>
          {/*       <Grid item md={5}>
        <Block>
          <h3 className={classes.headline}>Zahlen und Fakten zum Standort:</h3>
          <p>
            Künstler*innen online: 761
            <br />
            Werke online: 1.839
            <br />
            Historisch verkaufte Werke: über 2.200
            <br />
            Werke erfasst und archiviert: 6.673
            <br />
            Best performing artists: Amoako Boafo, Alex Ruthner, Christian
            Eisenberger, Alex Kiessling, Chris Rosa, Clemens Wolf, Markus
            Bacher.
          </p>
        </Block>
      </Grid> */}
          <Col md={10}>
            <Block black>
              <div className={classes.whiteText}>
                <Row horizontalAlign="center" verticalAlign="center">
                  <Col xs={"100px"}>
                    <img
                      className={classes.wlogo}
                      alt="Webcollector-Logo"
                      src={wlogo}
                    />
                  </Col>
                  <Col xs="190px">
                    <span className={classes.wLogoText}>Webcollector</span>
                  </Col>
                  <Col>
                    <div className={classes.alignCenter}>
                      <p className={classes.maxTextWidth}>
                        Artcare hat durch Technologie und jahrelange Erfahrung
                        in der systematischen Onlinearchivierung diese
                        Dienstleistungen entwickelt und optimiert. Aus der
                        Erfassung von über 6.000 Werken von Sammlern, Künstlern,
                        Auktionshäusern und Galerien entsteht eine stark
                        wachsende Plattform für Kunst.
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Block>
          </Col>
          <Col stretch md={5}>
            <Block stretch>
              <Headline variant="subheadline">Impressum</Headline>
              <Row vertical spacing={16} root={0}>
                <Col>
                  <p>
                    Artcare Marketing und Vertriebs GmbH
                    <br />
                    Rechte Bahngasse 30-32, 1030 Wien, Österreich
                    <br />
                    <a
                      className={classes.noTextDecoration}
                      href="https://artcare.at"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://artcare.at
                    </a>
                    <br />
                    <a
                      className={classes.noTextDecoration}
                      href="mailTo:office@artcare.at"
                    >
                      office@artcare.at
                    </a>
                  </p>
                </Col>
                <Col>
                  <Headline noMargin variant="subheadline">
                    UID-Nummer
                  </Headline>
                  <span>ATU74758924</span>
                </Col>
                <Col>
                  <Headline noMargin variant="subheadline">
                    Firmenbuchnummer
                  </Headline>
                  <span>FN 287030 d</span>
                </Col>
              </Row>
            </Block>
          </Col>
          <Col stretch md={5}>
            <Block stretch>
              <Headline variant="subheadline">GLN</Headline>
              <p>
                Alle Rechte vorbehalten. Das Herunterladen, Drucken und
                Speichern von Dateien dieser Website ist für den ausschließlich
                privaten Gebrauch gestattet. Jede darüber hinaus gehende
                Nutzung, insb. das Legen eines Hyperlinks beziehungsweise das
                Framing auf oder hinsichtlich auch nur von Teilen dieser Website
                bedarf unserer vorherigen schriftlichen Zustimmung.
                <br />
                Jeglicher Inhalt der Website und ihrer Teile wurde unter
                Anwendung größtmöglicher Sorgfalt erstellt und überwacht.
                Jegliche Haftung bezüglich Links zu anderen Webseiten und der
                Richtigkeit und Aktualität der enthaltenen Angaben sowie für
                Datenverlust oder andere technische Beeinträchtigungen, die beim
                Betrachten oder Herunterladen von Daten dieser Website entstehen
                können, ist ausgeschlossen.
                <br />
                <br />
                Es gilt österreichisches Recht unter Ausschluss seiner
                Verweisungsnormen.
                <br />
                <br />
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="/assets/documents/Nutzungsbedingungen.pdf"
                >
                  Nutzungsbedingungen
                </a>
              </p>
            </Block>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default About;
