import React from "react";
import { useClasses, ITheme, Row, Col } from "@maxeb/admin-ui";
import Block from "./Shared/Block";
import { IState as IArtworkState } from "./Artwork";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import classnames from "classnames";
import { TWishlist } from "../containers/Wishlist";
import { faHeart } from "@fortawesome/pro-light-svg-icons";
import { faHeart as faSolidHeart } from "@fortawesome/pro-solid-svg-icons";

export interface IProps {
  id: number;
  name?: string;
  title?: string;
  thumb?: string | null;
  thumbStatus?: IArtworkState["thumbStatus"];
  price?: string;
  available?: number;
  size?: string;
  onClick: () => void;
  wishlist: TWishlist;
}

const styles = (theme: ITheme) => ({
  wrapper: { position: "relative", cursor: "pointer" },
  thumb: {
    height: "220px",
    width: "calc(100% + 32px)",
    margin: "-16px -16px 16px -16px",
    backgroundColor: theme.palette.get("default"),

    textAlign: "center",
    paddingTop: "64px",
    boxSizing: "border-box",
    display: "block",
    backgroundPosition: "center",
    backgroundImage: (props: IProps) =>
      props.thumb ? `url("${props.thumb}")` : "none",
  },
  pending: {
    color: theme.palette.get("primary"),
    fontSize: "4rem",
  },
  none: {
    color: theme.palette.get("mediumGrey"),
    fontSize: "6rem",
  },
  textWrapper: { position: "relative", height: "2.3rem" },
  subTextWrapper: {
    position: "relative",
  },
  ellipsis: {
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "100%",
    textOverflow: "ellipsis",
    position: "absolute",
  },
  artist: {
    display: "inline-block",
    fontSize: "1.1rem",
    width: "calc(100% - 26px)",
  },
  title: {
    color: theme.palette.get("grey"),
    fontSize: "0.8rem",
    fontWeight: 100,
    top: "16px",
  },
  alignRight: {
    display: "block",
    textAlign: "right",
  },
  badge: {
    position: "absolute",
    right: "-16px",
    top: 0,
    padding: "4px 8px",
    boxShadow: "0px 2px 5px 0px #000000",
    fontSize: "0.8rem",
  },
  badgePrimary: {
    backgroundColor: theme.palette.get("primary"),
    color: "#fff",
  },
  badgeSuccess: {
    backgroundColor: theme.palette.get("success"),
    color: "#fff",
  },
  badgeWarning: {
    backgroundColor: "#d6ca00",
    color: "#fff",
  },
  badgeDanger: {
    backgroundColor: theme.palette.get("danger"),
    color: "#fff",
  },
  heartBtn: {
    position: "relative",
    top: "-10px",
    fontSize: "1.2rem",
  },
  primary: { color: theme.palette.get("primary") },
});

function Artwork(props: IProps) {
  const classes = useClasses(styles, props);
  const available = props.available;
  const [wishlist, addToWishlist, deleteFromWishlist] = props.wishlist;
  const url = `/artworks/${props.id}`;
  const isInWishList = wishlist.data.find((data) => data.url === url);

  return (
    <Block>
      <div className={classes.wrapper} onClick={props.onClick}>
        <div className={classes.thumb}>
          {available === 0 && (
            <span className={classnames([classes.badge, classes.badgeDanger])}>
              Verkauft
            </span>
          )}
          {available === 1 && (
            <span className={classnames([classes.badge, classes.badgeWarning])}>
              Auf Anfrage
            </span>
          )}
          {available === 2 && Boolean(props.price) && (
            <span className={classnames([classes.badge, classes.badgeSuccess])}>
              {props.price}€
            </span>
          )}
          {props.thumbStatus === "pending" && !props.thumb && (
            <FontAwesomeIcon
              className={classes.pending}
              icon={faSpinnerThird}
              spin
            />
          )}
          {props.thumbStatus === "none" && !props.thumb && (
            <FontAwesomeIcon className={classes.none} icon={faImage} />
          )}
        </div>
        <div className={classes.textWrapper}>
          <Row spacing={8} vertical root={0}>
            <Col>
              <span className={classnames([classes.ellipsis, classes.artist])}>
                {props.name}
              </span>
            </Col>
            <Col xs="rest-fixed">
              <div className={classes.subTextWrapper}>
                <span className={classnames([classes.ellipsis, classes.title])}>
                  {props.title}
                </span>
              </div>
            </Col>
            <Col xs="80px">
              <div className={classes.subTextWrapper}>
                <span
                  className={classnames([
                    classes.alignRight,
                    classes.heartBtn,
                    isInWishList && classes.primary,
                  ])}
                >
                  <FontAwesomeIcon
                    icon={isInWishList ? faSolidHeart : faHeart}
                    onClick={(e) => {
                      e.stopPropagation();

                      if (!isInWishList)
                        addToWishlist({
                          id: props.id || 0,
                          title: props.title || "",
                          artist: props.name || "",
                          url: url,
                          img: props.thumb || "",
                        });
                      else
                        deleteFromWishlist({
                          id: props.id || 0,
                          title: props.title || "",
                          artist: props.name || "",
                          url: url,
                          img: props.thumb || "",
                        });
                    }}
                  />
                </span>
                {Boolean(props.size) && (
                  <span
                    className={classnames([
                      classes.ellipsis,
                      classes.title,
                      classes.alignRight,
                    ])}
                  >
                    {props.size}
                  </span>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Block>
  );
}

export default Artwork;
