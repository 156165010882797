import React from "react";
import { useClasses, ITheme, Row, Col } from "@maxeb/admin-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLevelUp } from "@fortawesome/pro-light-svg-icons";
import { Link, useLocation, useParams } from "react-router-dom";
import classnames from "classnames";

const styles = (theme: ITheme) => ({
  subheader: {
    backgroundColor: "#000",
    color: "#a1a3a6",
    position: "fixed",
    top: "55px",
    borderBottom: "1px solid #3c3c3c",
    fontSize: "1rem",
    zIndex: 9,
    width: "100%",
    left: 0,
    padding: "16px 16px",
    [theme.breakpoints.up("md")]: {
      paddingLeft: "235px",
    },
  },
  placeholder: {
    height: "35px",
  },
  icon: {
    fontSize: "1rem",
    transform: "rotate(90deg)",
  },
  link: {
    color: "#a1a3a6",
    textDecoration: "none",
  },
  active: {
    color: "#fff",
  },
});

const isActive = (link: string, path: string) => {
  return link === path;
};

export interface IProps {
  archive?: boolean;
}

function Menu(props: IProps) {
  const classes = useClasses(styles);
  const location = useLocation();
  const params = useParams();
  const basePath = `/auctions/${params.id}`;

  return (
    <div className={classes.placeholder}>
      <div className={classes.subheader}>
        <Row spacing={16} root={0}>
          <Col xs="hidden" sm="hidden" md="auto">
            <FontAwesomeIcon className={classes.icon} icon={faLevelUp} />
          </Col>
          <Col xs="auto">
            <Link
              className={classnames([
                classes.link,
                isActive(basePath, location.pathname) && classes.active,
              ])}
              to={basePath}
            >
              Werke
            </Link>
          </Col>
          {!props.archive && (
            <Col xs="auto">
              <Link
                className={classnames([
                  classes.link,
                  isActive(`${basePath}/book`, location.pathname) &&
                    classes.active,
                ])}
                to={`${basePath}/book`}
              >
                Besichtigung
              </Link>
            </Col>
          )}
          <Col xs="auto">
            <a
              className={classnames([
                classes.link,
                isActive(`${basePath}/conditions`, location.pathname) &&
                  classes.active,
              ])}
              rel="noreferrer"
              target="_blank"
              href={`/assets/documents/auctions/Auktionsbedingungen-${params.id}.pdf`}
            >
              Bedingungen
            </a>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Menu;
