import React from "react";
import { useClasses, ITheme, Container, Col, Row } from "@maxeb/admin-ui";

import Block from "./Shared/Block";

import WizzardHead from "./Shared/WizzardHead";
import Headline from "./Shared/Headline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faEnvelope,
  faPhone,
} from "@fortawesome/pro-light-svg-icons";
import { faExclamationTriangle } from "@fortawesome/pro-duotone-svg-icons";
import Footer from "./Shared/Footer";

export interface IProps {}

const styles = (theme: ITheme) => ({
  list: {
    listStyle: "none",
    padding: "0 0 0 0px",
    margin: 0,
    "& > li": {
      padding: "8px 0px",
    },
  },
  icon: {
    fontSize: "1.2rem",
    marginRight: "8px",
  },
  successIcon: {
    color: theme.palette.get("danger"),
    fontSize: "150px",
  },
});

export default function OrderError(props: IProps) {
  const classes = useClasses(styles);

  return (
    <>
      <Container>
        <Block>
          <WizzardHead active={3} />
          <Row
            spacing={16}
            vertical
            root={{ left: 0, top: 0, right: 0, bottom: 0 }}
            horizontalAlign="center"
          >
            <Col xs="hidden" sm="hidden" md="hidden" lg="200px">
              <FontAwesomeIcon
                className={classes.successIcon}
                icon={faExclamationTriangle}
              />
            </Col>
            <Col lg="500px">
              <Headline noMargin variant="h1">
                Fehler!
              </Headline>
              <p>
                Die Zahlung konnte leider <b>nicht</b> abgewickelt werden.
              </p>
              <Headline variant="subheadline">Haben Sie Fragen?</Headline>
              <p>Bitte zögern Sie nicht uns zu kontaktieren.</p>
              <Col md={5} lg={10 / 3}>
                <ul className={classes.list}>
                  <li>
                    <FontAwesomeIcon
                      className={classes.icon}
                      icon={faBuilding}
                    />
                    Rechte Bahngasse 30-32, 1030 Wien
                  </li>
                  <li className={classes.padding}>
                    <FontAwesomeIcon className={classes.icon} icon={faPhone} />
                    +43 (0) 676 432 74 31
                  </li>
                  <li className={classes.padding}>
                    <FontAwesomeIcon
                      className={classes.icon}
                      icon={faEnvelope}
                    />
                    office@artcare.at
                  </li>
                </ul>
              </Col>
            </Col>
          </Row>
        </Block>
      </Container>
      <Footer />
    </>
  );
}
