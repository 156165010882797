import { request, domain } from "./Abstract";
import { useState } from "react";

export interface IContact {
  status: "init" | "pending" | "success" | "ok" | "error";
  errors: {
    name: string | undefined;
    email: string | undefined;
    phone: string | undefined;
    description: string | undefined;
    agbs: string | undefined;
  };
  values: {
    firstname: string | undefined;
    lastname: string | undefined;
    email: string | undefined;
    phone: string | undefined;
    description: string | undefined;
    agbs: boolean;
  };
}

export type TSetContact = (contact: IContact) => void;

export function useContact(): [IContact, TSetContact] {
  const [state, setState] = useState<IContact>({
    status: "init",
    errors: {
      name: "",
      email: "",
      phone: "",
      description: "",
      agbs: "",
    },
    values: {
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      description: "",
      agbs: false,
    },
  });

  return [
    state,
    (contact: IContact) => {
      if (state.status !== "pending") setState(contact);
    },
  ];
}

export async function saveContact(contact: IContact, setContact: TSetContact) {
  try {
    setContact({ ...contact, status: "pending" });
    const { values } = contact;
    const payload = {
      name:
        (values.firstname || "") +
        (values.firstname && values.lastname && " ") +
        (values.lastname || ""),
      phone: values.phone,
      email: values.email,
      description: values.description,
      agbs: values.agbs,
      cart: "[]",
    };

    const result = await request(
      "POST",
      domain + "/api/cart/add",
      {},
      {},
      payload
    );
    const response = JSON.parse(result.response);

    if (response.error) {
      setContact({
        ...contact,
        errors: { ...(response.errors || {}) },
        status: "error",
      });
    } else {
      setContact({ ...contact, status: "success" });
    }
  } catch (error) {
    setContact({ ...contact, status: "error" });
  }
}
