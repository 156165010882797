import React, { useState } from "react";
import { useClasses, ITheme, Container, Row, Col } from "@maxeb/admin-ui";
import logo from "../../resources/images/logo.svg";
import { faBars } from "@fortawesome/pro-light-svg-icons/faBars";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes";
import { Link, useLocation } from "react-router-dom";
import classnames from "classnames";
import { TLinks } from "./Menu";
import {
  faFacebookSquare,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

const styles = (theme: ITheme) => ({
  link: {
    textDecoration: "none",
    color: "#a1a3a6",
  },
  active: { color: "#fff" },
  logo: {
    height: "50px",
  },
  respMenu: {},
  respBtn: {
    textAlign: "right",
    fontSize: "1.5rem",
    paddingRight: "8px",
  },
  respIcon: {
    position: "relative",
    top: "-4px",
  },
});

export interface IProps {
  links: TLinks;
}

function MenuSmall(props: IProps) {
  const classes = useClasses(styles);
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const path = location.pathname;
  return (
    <Container fluid>
      <Row verticalAlign="center" root={{ left: 0, right: 0 }} spacing={16}>
        <Col xs="auto">
          <img className={classes.logo} src={logo} alt="Artcare-Logo" />
        </Col>
        <Col xs="rest" md="hidden" lg="hidden" xl="hidden">
          <div className={classes.respBtn}>
            {!open && (
              <FontAwesomeIcon
                className={classes.respIcon}
                icon={faBars}
                onClick={(e) => {
                  e.preventDefault();
                  setOpen(true);
                }}
              />
            )}
            {open && (
              <FontAwesomeIcon
                className={classes.respIcon}
                icon={faTimes}
                onClick={(e) => {
                  e.preventDefault();
                  setOpen(false);
                }}
              />
            )}
          </div>
        </Col>
      </Row>
      {open && (
        <div className={classes.respMenu}>
          <Row
            horizontalAlign="center"
            verticalAlign="center"
            vertical
            root={{ top: 0 }}
            spacing={16}
          >
            {props.links.map((link) => {
              return (
                <Col xs={10} key={`link-for-${link.to}-resp`}>
                  <Link
                    className={classnames([
                      classes.link,
                      path === link.to && classes.active,
                    ])}
                    to={link.to}
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    {typeof link.label === "string" && link.label}
                    {typeof link.label !== "string" && (
                      <FontAwesomeIcon icon={link.label} />
                    )}
                  </Link>
                </Col>
              );
            })}
            <Col xs={10}>
              <a
                className={classes.link}
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/artcare.at/"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </Col>
            <Col xs={10}>
              <a
                className={classes.link}
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/artcare.at"
              >
                <FontAwesomeIcon icon={faFacebookSquare} />
              </a>
            </Col>
          </Row>
        </div>
      )}
    </Container>
  );
}

export default MenuSmall;
