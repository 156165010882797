import Auction, { IProps as IAuction } from "./ListItem";
import Headline from "../Shared/Headline";
import { useClasses, ITheme, Container, Button, Row } from "@maxeb/admin-ui";

import boa21Logo from "../../resources/images/auctions/BOA21.png";
import yaa21Logo from "../../resources/images/auctions/YAA21.png";
import parallel21Logo from "../../resources/images/auctions/parallel.jpg";
import derStandard from "../../resources/images/auctions/derStandard.jpg";
import Footer from "../Shared/Footer";

import faaLogo from "../../resources/images/auctions/FAA_22.jpg";

const current: IAuction[] = [];

const old: IAuction[] = [
  {
    id: "faa22",
    name: "Female Art Auction",
    logo: faaLogo,
    description: (
      <>
        <h3 style={{ marginTop: 0, marginBottom: "13px" }}>
          MOST WANTED FEMALE* ART AUCTION 2022
          <br />
          <small>
            ist beendet! Wir bedanken uns herzlich bei allen Bieter*innen und
            Künstlerinnen für Ihre Teilnahme!{" "}
          </small>
        </h3>
        <p style={{ margin: "0px 0px" }}>
          Der Nachverkauf läuft noch bis 10.4.2022. Im Nachverkauf können die
          Werke zum Rufpreis plus 25% Aufgeld plus Umsatzsteuer erworben werden.
          Besichtigung der Werke im FLOMYCA, Voltagasse 42, 1210 Wien, nach
          Vereinbarung unter{" "}
          <a href="mailTo:office@artcare.at">office@artcare.at</a>.
        </p>
        <p>
          Mit herzlichen Grüßen
          <br /> Ihr Artcare Team
        </p>
      </>
    ),
  },
  {
    id: "stdauc",
    name: "Der Standard",
    logo: derStandard,
    description: (
      <span>
        <b>22.01.2022 - 05.02.2022 21:00</b>
        <br />
        <br />
        Ersteigern Sie ab 1.12.2021 Werke aus unserer BEST OF ARTCARE 2021
        AUSWAHL!
        <br />
        Wir gratulieren zur 10.000. Ausgabe des STANDARD! Ersteigern Sie das
        COVER und 4 weitere NFTs, die aus 85 eingereichten Werken ausgesucht
        wurden.
        <br />
        Der gesamte Erlös kommt StudentInnen der Akademie der bildenden Künste
        in Notsituationen zugute.
      </span>
    ),
  },
  {
    id: "boa21",
    name: "BOA21",
    logo: boa21Logo,
    description: (
      <span>
        Ersteigern Sie ab 1.12.2021 Werke aus unserer BEST OF ARTCARE 2021
        AUSWAHL!
        <br />
        LOT 1 - 30 endet am 18.12.2021, 21:00
        <br />
        LOT 31 - 40 endet am 19.12.2021, 21:00
        <br />
        LOT 41 - 50 endet am 20.12.2021, 21:00
      </span>
    ),
  },
  {
    id: "yaa21",
    name: "YAA21",
    logo: yaa21Logo,
    description: (
      <span>
        Die YAA ist eine Förderinitiative für aufstrebende junge KünstlerInnen,
        denen einerseits eine Plattform für die Präsentation ihrer Werke geboten
        wird, vor allem aber – mit <b>90% des Hammerpreises</b> – ein besonders
        hoher Anteil am erzielten Verkaufspreis ausbezahlt wird. Das reduzierte
        Aufgeld von 12,5% wird zur Deckung der Veranstaltungskosten verwendet.
        <br />
        Seit 2007 wurden an den bisherigen 14 Auktionsabenden über 600 Exponate
        verkauft und ein Gesamtumsatz von rund 850.000€ erzielt.
        <br />
        <br />
        Ihr Artcare Team
      </span>
    ),
  },
  {
    id: "p21",
    name: "Paralle 2021",
    logo: parallel21Logo,
    description: (
      <span>
        Artcare steht seit vielen Jahren nicht nur für ein bahnbrechendes
        Kunstangebot sondern auch für Innovation und neue Ansätze im
        Kunstverkauf.
        <br />
        <br />
        Dieser Linie folgend werden alle von uns auf der Parallel Vienna 2021
        ausgestellten Werke parallel im Rahmen einer Online Auktion, die während
        der Ausstellung und eine Woche darüber hinaus - bis 18.9.2021 - laufen
        wird, einem breiten Publikum zugänglich gemacht.
      </span>
    ),
  },
];

const styles = (theme: ITheme) => ({
  wrapper: {
    padding: "16px 0px 0px 0px",
  },
  current: {
    minHeight: "240px",
  },
});

function Auctions() {
  const classes = useClasses(styles);
  return (
    <div className={classes.wrapper}>
      <Container>
        {current.length > 0 && (
          <div className={classes.current}>
            <Headline variant="bodyheadline">AKTUELLE</Headline>
            <Row spacing={16} vertical root={{ top: 0, left: 0, right: 0 }}>
              {current.map((auction) => {
                return <Auction key={`${auction.id}-auction`} {...auction} />;
              })}
            </Row>
          </div>
        )}
        {old.length > 0 && (
          <div className={classes.current}>
            <Row
              vertical
              root={{ left: 0, top: 16, right: 0, bottom: 16 }}
              horizontalAlign="left"
            >
              <Button
                xs="240px"
                primary
                onClick={() =>
                  window.open(
                    "https://auction.artcare.at/",
                    "_blank",
                    "noreferrer, noopener"
                  )
                }
              >
                Zu den aktuellen Auktionen
              </Button>
            </Row>
            <Headline variant="bodyheadline">VERGANGENE</Headline>
            <Row spacing={16} vertical root={{ top: 0, left: 0, right: 0 }}>
              {old.map((auction) => {
                return (
                  <Auction key={`${auction.id}-auction`} archive {...auction} />
                );
              })}
            </Row>
          </div>
        )}
      </Container>
      <Footer />
    </div>
  );
}

export default Auctions;
