import React from "react";
import ArtworkCard from "./ArtworkCard";
import { IArtwork as IArtworkProps } from "../containers/Artworks";
import ArtworkDetails from "./ArtworkDetails";
import { TWishlist } from "../containers/Wishlist";

export interface IProps extends IArtworkProps {
  wishlist: TWishlist;
}
export interface IState {
  thumb?: string | null;
  thumbStatus: "pending" | "ok" | "none";
  mockup?: string | null;
  mockupStatus: "pending" | "ok" | "none";
  open: boolean;
  debug?: string;
}
const hasThumb = (props: IProps) => {
  return Boolean(
    props.originalThumbId &&
      props.originalThumbExtension &&
      props.originalThumbName &&
      props.originalThumbUpdateDate
  );
};

export default class Artwork extends React.Component<IProps, IState> {
  MOUNT = false;
  constructor(props: IProps) {
    super(props);
    this.state = {
      open: this.props.open,
      thumbStatus: hasThumb(props) ? "pending" : "none",
      mockupStatus: "pending",
    };
    this.loadImage = this.loadImage.bind(this);
    this.loadDetails = this.loadDetails.bind(this);
    this.setImage = this.setImage.bind(this);
  }
  componentDidMount() {
    this.MOUNT = true;
    this.loadImage("thumb", {
      id: this.props.originalThumbId,
      updateDate: this.props.originalThumbUpdateDate,
      extension: this.props.originalThumbExtension,
      name: this.props.originalThumbName,
    });
    if (this.state.open) {
      this.loadDetails();
    }
    //if (this.props.active) this.loadDetails();
  }
  componentWillUnmount() {
    this.MOUNT = false;
  }
  setImage(type: "thumb" | "mockup", base64: string) {
    if (this.MOUNT)
      this.setState({ ...this.state, [type]: base64, [`${type}Status`]: "ok" });
  }
  async loadImage(
    type: "thumb" | "mockup",
    data: {
      id?: number;
      updateDate?: string;
      extension?: string;
      name?: string;
    }
  ) {
    if (
      typeof this.state[type] === "undefined" &&
      data.id &&
      data.extension &&
      data.name &&
      data.updateDate
    ) {
      const reader = new window.FileReader();
      const { setImage } = this;
      reader.onload = function () {
        setImage(type, this.result + "");
      };
      reader.onerror = () => {
        if (this.MOUNT)
          this.setState({
            ...this.state,
            [type]: null,
            [`${type}Status`]: "none",
          });
      };
      const url = `https://artware2.datenbanker.at/api/picture/public/${data.id}/lg/0/${data.name}.${data.extension}`;

      try {
        return await fetch(url).then(async (response) =>
          reader.readAsDataURL(await response.blob())
        );
      } catch (err) {
        this.setState({});
      }
    } else if (typeof this.state[type] !== "string") {
      this.setState({ ...this.state, [type]: null });
    }
    return false;
  }
  async loadDetails() {
    try {
      //load details
      const received = await fetch(
        `https://artware2.datenbanker.at/api/artwork/${this.props.id}/public`
      ).then((response) => response.json());
      //load mock
      const { mockup } = received.artwork.data;

      if (mockup.hasData)
        this.loadImage("mockup", {
          id: mockup.data.id,
          name: mockup.data.name,
          extension: mockup.data.extension,
          updateDate: mockup.data.updateDate,
        });
      else if (this.MOUNT)
        this.setState({ ...this.state, mockup: null, mockupStatus: "none" });
    } catch (error) {
      if (this.MOUNT)
        this.setState({ ...this.state, mockup: null, mockupStatus: "none" });
    }
  }
  getSize(props: IProps) {
    const { width, height, depth } = props;
    let size = "";
    const append = (toAdd?: number) => {
      const value = Math.round(toAdd || 0);
      if (value !== 0) size += `${value}x`;
    };
    append(width);
    append(height);
    append(depth);

    if (size.charAt(size.length - 1) === "x") {
      size = size.slice(0, size.length - 1);
    }
    return size;
  }
  getPrice(props: IProps) {
    if (props.price) return props.price.toLocaleString();
  }
  render() {
    return (
      <div>
        {this.state.open && (
          <ArtworkDetails
            id={this.props.id}
            wishlist={this.props.wishlist}
            available={this.props.available}
            artist={this.props.name}
            artistDescription={this.props.artistDescription}
            title={this.props.title}
            thumbUrl={`https://artware2.datenbanker.at/api/picture/public/${this.props.originalThumbId}/lg/0/${this.props.originalThumbName}.${this.props.originalThumbExtension}`}
            thumb={this.state.thumb}
            thumbStatus={this.state.thumbStatus}
            mockup={this.state.mockup}
            mockupStatus={this.state.mockupStatus}
            description={this.props.description}
            year={this.props.year}
            category={this.props.category}
            technique={this.props.technique}
            price={this.getPrice(this.props)}
            size={this.getSize(this.props)}
            onClose={() => {
              this.setState({ ...this.state, open: false });
            }}
          />
        )}
        <ArtworkCard
          wishlist={this.props.wishlist}
          available={this.props.available}
          id={this.props.id}
          thumb={this.state.thumb}
          thumbStatus={this.state.thumbStatus}
          name={this.props.name}
          title={this.props.title}
          price={this.getPrice(this.props)}
          size={this.getSize(this.props)}
          onClick={() => {
            this.setState({ ...this.state, open: true });
            this.loadDetails();
          }}
        />
      </div>
    );
  }
}
