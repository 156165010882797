import { Container, Row, Col } from "@maxeb/admin-ui";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Block from "./Shared/Block";
import Headline from "./Shared/Headline";
import parallelLogo from "../resources/images/auctions/parallel.jpg";
import yaa21Logo from "../resources/images/auctions/YAA21.png";
import flomycaLogo from "../resources/images/flomyca.jpg";
import { Link } from "react-router-dom";
import Footer from "./Shared/Footer";

const news: { time: string; text: React.ReactNode; logo?: React.ReactNode }[] =
  [
    {
      time: "21.10. – 25.11.2022",
      text: (
        <>
          <Headline variant="h2">
            <span
              style={{ color: "rgb(220, 120, 0)", textDecoration: "underline" }}
            >
              New Beginnings{" "}
            </span>{" "}
            <br /> <small>Group exhibition curated by Paula Marschalek</small>
          </Headline>
          <ul>
            <li>Francesca Aldegani</li>
            <li>Guadalupe Aldrete</li>
            <li>Louise Deininger</li>
            <li>Aklima Iqbal</li>
            <li>Leon Kapeller</li>
            <li>Derek Roberts</li>
          </ul>
          <h3 style={{ marginBottom: "4px", color: "rgb(220, 120, 0)" }}>
            Hard Facts
          </h3>
          <p style={{ marginTop: "0px" }}>
            <b>Exhibition period</b> 21. 10. - 25. 11. 2022 Wednesday - Friday:
            15:00 - 19:00 & by appointment{" "}
            <a href="mailTo:office@marschalek.art">office@marschalek.art</a>
            <br />
            <b>Vernissage & Performance by Guadalupe Aldrete</b> 20. October
            2022, 18:00 - 22:00
          </p>
          <h3 style={{ marginBottom: "4px", color: "rgb(220, 120, 0)" }}>
            Special Program
          </h3>
          <p style={{ marginTop: "0px" }}>
            <b>Curator‘s Tour</b> Thursdays, 03. 11., 10. 11., 17. 11. 2022,
            15:00 - 19:00
            <br />
            <b>Artist Talk</b> 17.11. 2022, 18:00
            <br />
            <b>Network Brunch & Studio Visit</b> 05.11. 2022, 10:00 - 12:00
            <br />
            <br />
            <b>Location</b>
            <br />
            FLOMYCA - Floridsdorf Museum of Young & Contemporary Art Morsegasse
            1C, 1210 Vienna
          </p>
          <h3 style={{ marginBottom: "4px", color: "rgb(220, 120, 0)" }}>
            New Beginnings
          </h3>
          <p style={{ marginTop: "0px" }}>
            The interdisciplinary exhibition project „New Beginnings“ sets
            artistic works by{" "}
            <b>
              Francesca Aldegani, Guadalupe Aldrete, Louise Deininger, Aklima
              Iqbal, Leon Kapeller and Derek Roberts
            </b>{" "}
            in dialogue with each other and shows different perspectives on the
            topic of diversity. The project provides insight into how one‘s own
            culture and social or societal backgrounds react to a new context.
            Since all artists come from different corners of the world (USA,
            East Africa-Kenya_Uganda, Mexico, Italy, Bangladesh and Austria),
            the focus is on observing how everyone finds themselves here. We
            will explore what diversity means on a small, large, private and
            social scale and NEW BEGINNINGS what significance and potential it
            has for creatives.{" "}
          </p>
          <p>
            <b>
              <a href="https://auction.artcare.at/flomyca">Zur Auktion</a>
            </b>
          </p>
        </>
      ),
    },
    {
      time: "30.11.2021",
      text: (
        <span>
          {" "}
          <Headline variant="h2">BEST OF ARTCARE 2021 AUKTION</Headline>
          <p>
            Bieten Sie jetzt online für die Werke unserer BEST OF ARTCARE 2021
            AUKTION. Sichern Sie sich Ihr Werk und starten Sie bei 50% des
            Listenpreises.
            <br />
            <br />
            Werke von: Franz West, Tacita Dean, Tal R, Zenita Komad, Yigal
            Ozeri, Stefan Kaluza, Alex Ruthner uvm.
          </p>
        </span>
      ),
      logo: (
        <iframe
          title="boa-teaser"
          width="100%"
          height="auto"
          src="https://www.youtube.com/embed/WqrC6wyOt8k"
          frameBorder={0}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      ),
    },
    {
      time: "29.08.2021",
      text: (
        <div>
          <p>Liebe KunstfreundInnen,</p>
          <p>
            herzlich willkommen zur{" "}
            <Link to="/auctions/archive/yaa21">YOUNG ART AUCTION 2021</Link> im{" "}
            <a href="https://www.facebook.com/flomyca/">
              FLOMYCA Museum of Young and Contemporary Art
            </a>
            .
          </p>
          <p>
            Die YAA ist eine Förderinitiative für aufstrebende junge
            KünstlerInnen, denen einerseits eine Plattform für die Präsentation
            ihrer Werke geboten wird, vor allem aber –{" "}
            <b>mit 90% des Hammerpreises</b> – ein besonders hoher Anteil am
            erzielten Verkaufspreis ausbezahlt wird. Das reduzierte Aufgeld von
            12,5% wird zur Deckung der Veranstaltungskosten verwendet. Seit 2007
            wurden an den bisherigen 14 Auktionsabenden über 600 Exponate
            verkauft und ein Gesamtumsatz von rund EUR 850.000 erzielt.
          </p>
          <p>
            Bieten Sie online für die hoffnungsvollsten jungen KünstlerInnen.
            Dieses Jahr wird die Onlineauktion von 138 Werken an drei Tagen
            vorgenommen. Am 25., 26. und 27.11.2021 um 21:00 Uhr endet die
            Gebotsfrist für jeweils 1/3 der Werke. Die Werke können im FLOMYCA,
            Floridsdorf Museum of Young and Contemporary Art besichtigt werden.
          </p>
          <p>
            Ein großes Dankeschön an die KünstlerInnen, die Jury Ivana Ridler,
            Günther Oberhollenzer, Raimund Deininger und das gesamte ARTCARE
            TEAM für ihr großes Engagement.
          </p>
          <p>
            Wir freuen uns auf Ihre Gebote!
            <br />
            Ihr Artcare Team
          </p>
        </div>
      ),
      logo: (
        <div>
          <iframe
            title="yaa-teaser"
            width="100%"
            height="auto"
            src="https://www.youtube.com/embed/RiAkz1ceA78"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
          <div style={{ textAlign: "center" }}>
            <Link to="/auctions/archive/yaa21">
              <img
                alt="yaalogo"
                style={{ maxWidth: "150px", marginBottom: "16px" }}
                src={yaa21Logo}
              />
            </Link>
          </div>
          <div style={{ textAlign: "center" }}>
            <img alt="logo" style={{ maxWidth: "150px" }} src={flomycaLogo} />
          </div>
        </div>
      ),
    },
    {
      time: "10.11.2021",
      text: (
        <span>
          <div>
            <h1>
              <Link to="/auctions/archive/p21">Parallel 2021</Link>
            </h1>
          </div>
          <div>
            <p>
              Artcare steht seit vielen Jahren nicht nur für ein bahnbrechendes
              Kunstangebot sondern auch für Innovation und neue Ansätze im
              Kunstverkauf.
            </p>
            <p>
              Dieser Linie folgend werden alle von uns auf der Parallel Vienna
              2021 ausgestellten Werke parallel im Rahmen einer{" "}
              <b>Online Auktion</b>, die während der Ausstellung und eine Woche
              darüber hinaus - bis 18.9.2021 - laufen wird, einem breiten
              Publikum zugänglich gemacht.
            </p>
            <p>
              Unsere diesjährige Parallel-Ausstellung ist in 5 Themenbereiche
              gegliedert:
            </p>
            <p>
              <b>Iranische Kunst</b>
              <br />
              Die österreichisch-iranische Kuratorin Shanay Artemis Hubmann hat
              5 spannende zeitgenössische Positionen vermittelt und für die
              Parallel nach Österreich geholt. Diese Werke haben wir mit
              Arbeiten der iranischen Diaspora in Wien ergänzt.
            </p>
            <p>
              <b>Afrikanische Kunst</b>
              <br />
              In Reaktion auf den seit einigen Jahren starken inhaltlichen Fokus
              auf Kunst aus Afrika, haben wir neben absoluten Weltstars aus
              dieser Szene auch einige der spannendsten Newcomer ausgewählt.
            </p>
            <p>
              <b>Female Art</b>
              <br />
              Eine starke Präferenz zu Female Art wird immer ein Anliegen von
              Artcare, der Initiatorin der ersten Female Art Auction in
              Österreich, sein. Auch dieses Mal präsentieren wir neben schon
              bekannten Künstlerinnen Werke von jungen aufstrebenden Frauen.
            </p>
            <p>
              <b>Emerging Artists</b>
              <br />
              Seit Anfang der Neunzigerjahre erlebt Wien einen starken Zuzug von
              Künstler*innen aus aller Welt, die an den hoch angesehenen
              Kunsthochschulen der Stadt studieren. Wie kaum eine Stadt steht
              Wien für kulturelle und künstlerische Vielfalt. Der pulsierenden
              Kunstproduktion tragen wir mit Begeisterung Rechnung. More to come
              in FLOMYCA.
            </p>
            <p>
              <b>Arrivierte Künstler*innen</b>
              <br />
              Artcare hat durch Technologie und jahrelange Erfahrung in der
              systematischen Onlinearchivierung von Kunstwerken Zugang zu
              herausragenden Werken weltbekannter Künstler*innen.
            </p>
          </div>
        </span>
      ),
      logo: (
        <div>
          <img alt="logo" style={{ maxWidth: "100%" }} src={parallelLogo} />
        </div>
      ),
    },
  ];

function News() {
  return (
    <div style={{ paddingTop: "16px" }}>
      <Container>
        <Row spacing={16} vertical root={0}>
          {news.map((item) => {
            return (
              <Row spacing={16} vertical root={0}>
                <Col>
                  <Headline noMargin variant="bodyheadline">
                    {item.time}
                  </Headline>
                </Col>
                <Col>
                  <Block>
                    <Row spacing={16} vertical root={0}>
                      {item.logo && <Col md="400px">{item.logo}</Col>}
                      <Col md="rest-fixed">{item.text}</Col>
                    </Row>
                  </Block>
                </Col>
              </Row>
            );
          })}
        </Row>
      </Container>
      <Footer />{" "}
    </div>
  );
}

export default News;
