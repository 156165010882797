import Footer from "./Shared/Footer";

function Contact() {
  return (
    <>
      <iframe
        scrolling="no"
        style={{ border: "none" }}
        title="Contact Form"
        width="100%"
        height="1150px"
        src="https://crm.zoho.eu/crm/WebFormServeServlet?rid=1ffbdfc01b36cdbd9e7b0350332577b086123dd184f1dc57b23364873a03d267gidb705daa99dd54ce2d347eaa8cefa7866e7a78a2e85276706099b9bef395593c5"
      />
      <Footer />
    </>
  );
}

export default Contact;
