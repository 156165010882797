import React from "react";
import { useClasses, ITheme, Row, Col } from "@maxeb/admin-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faMoneyBill,
  faBoxCheck,
} from "@fortawesome/pro-light-svg-icons";
import classnames from "classnames";

export interface IProps {
  active?: number;
}

const style = (theme: ITheme) => ({
  wrapper: {
    position: "relative",
  },
  line: {
    marginTop: "-48px",
    marginBottom: "40px",
    top: "25px",
    left: 0,
    right: 0,
    backgroundColor: theme.palette.get("default"),
    height: "10px",
  },
  icon: {
    zIndex: 2,
    fontSize: "1.5rem",
    backgroundColor: theme.palette.get("default"),
    color: theme.palette.get("grey"),
    display: "inline-block",
    width: "50px",
    height: "50px",
    borderRadius: "100%",
    textAlign: "center",
    border: "2px solid #fff",
    paddingTop: "6px",
    boxSizing: "border-box",
  },
  aligner: {
    textAlign: "center",
  },
  active: {
    backgroundColor: theme.palette.get("primary"),
    color: "#fff",
  },
});

export default function Block(props: IProps) {
  const classes = useClasses(style, props);
  const active = props.active;
  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <Row spacing={16} vertical root={{ top: 0, left: 0, right: 0 }}>
          <Col xs={10 / 3}>
            <div className={classes.aligner}>
              <div
                style={{ paddingLeft: "1px", paddingTop: "8px" }}
                className={classnames([
                  classes.icon,
                  active === 1 && classes.active,
                ])}
              >
                <FontAwesomeIcon icon={faAddressCard} />
              </div>
            </div>
          </Col>
          <Col xs={10 / 3}>
            <div className={classes.aligner}>
              <div
                style={{ paddingLeft: "1px", paddingTop: "7px" }}
                className={classnames([
                  classes.icon,
                  active === 2 && classes.active,
                ])}
              >
                <FontAwesomeIcon icon={faMoneyBill} />
              </div>
            </div>
          </Col>
          <Col xs={10 / 3}>
            <div className={classes.aligner}>
              <div
                style={{ paddingLeft: "0px", paddingTop: "7px" }}
                className={classnames([
                  classes.icon,
                  active === 3 && classes.active,
                ])}
              >
                <FontAwesomeIcon icon={faBoxCheck} />
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className={classes.line} />
    </div>
  );
}
