import { request, domain } from "./Abstract";
import { useState } from "react";

export interface ICheckout {
  status: "init" | "pending" | "success" | "ok" | "error";
  errors: {
    name: string | undefined;
    email: string | undefined;
    phone: string | undefined;
    address: string | undefined;
    plz: string | undefined;
    city: string | undefined;
    store: string | undefined;
    dsgvo: string | undefined;
    agbs: string | undefined;
    artwork?: string;
  };
  values: {
    isCompany: string | undefined;
    firstname: string | undefined;
    lastname: string | undefined;
    email: string | undefined;
    phone: string | undefined;
    address: string | undefined;
    plz: string | undefined;
    city: string | undefined;
    store: string | undefined;
    dsgvo: boolean;
    agbs: boolean;
  };
}

export type TSetCheckout = (contact: ICheckout) => void;

export function useCheckout(): [ICheckout, TSetCheckout] {
  const [state, setState] = useState<ICheckout>({
    status: "init",
    errors: {
      name: "",
      email: "",
      phone: "",
      address: "",
      plz: "",
      city: "",
      store: "",
      dsgvo: "",
      agbs: "",
    },
    values: {
      isCompany: "person",
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      address: "",
      plz: "",
      city: "",
      store: "",
      agbs: false,
      dsgvo: false,
    },
  });

  return [
    state,
    (checkout: ICheckout) => {
      if (state.status !== "pending") setState(checkout);
    },
  ];
}

export async function saveCheckout(
  checkout: ICheckout,
  setCheckout: TSetCheckout,
  artwork: number
) {
  let hasError = false;
  const errors = {
    name: "",
    email: "",
    phone: "",
    address: "",
    plz: "",
    city: "",
    store: "",
    dsgvo: "",
    agbs: "",
  };
  if (!checkout.values.agbs) {
    hasError = true;
    errors.agbs = "Bitte akzeptieren Sie unsere AGBs.";
  }
  if (!checkout.values.dsgvo) {
    hasError = true;
    errors.dsgvo = "Bitte akzeptieren Sie unsere DSGVO.";
  }

  if (hasError) {
    return setCheckout({ ...checkout, errors });
  }

  try {
    setCheckout({ ...checkout, errors, status: "pending" });
    const payload = {
      ...checkout.values,
      name:
        checkout.values.isCompany === "person"
          ? `${checkout.values.lastname}, ${checkout.values.firstname}`
          : checkout.values.lastname,
      isCompany: checkout.values.isCompany === "person" ? 0 : 1,
      artwork,
    };

    const result = await request(
      "POST",
      domain + "/api/checkout",
      {},
      {},
      payload
    );
    const response = JSON.parse(result.response);

    if (response.error) {
      setCheckout({
        ...checkout,
        errors: { ...(response.errors || {}) },
        status: "error",
      });
    } else {
      setCheckout({ ...checkout, status: "success" });
      window.location = response.link;
    }
  } catch (error) {
    setCheckout({ ...checkout, status: "error" });
  }
}
