import React from "react";
import { useClasses, ITheme } from "@maxeb/admin-ui";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/pro-solid-svg-icons";
import { TWishlist } from "../../containers/Wishlist";

export interface IProps {
  isActive: boolean;
  wishlist: TWishlist;
}

const style = (theme: ITheme) => ({
  link: {
    textDecoration: "none",
    color: "#a1a3a6",
    position: "relative",
  },
  active: {
    color: "#fff",
  },
  badge: {
    display: "block",
    backgroundColor: "black",
    position: "absolute",
    left: 8,
    top: 8,
    fontSize: "0.6rem",
    textAlign: "center",
    boxSizing: "border-box",
    borderRadius: "100%",
    width: "12px",
    height: "12px",
  },
});

export default function WhishlistBTN(props: IProps) {
  const classes = useClasses(style, props);
  const [wishlist] = props.wishlist;
  const count = wishlist.data.length;
  return (
    <Link
      className={classnames([classes.link, props.isActive && classes.active])}
      to="/wishlist"
    >
      <FontAwesomeIcon icon={faHeart} />
      {count > 0 && <div className={classes.badge}>{count}</div>}
    </Link>
  );
}
