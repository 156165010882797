import React from "react";
import { useClasses, ITheme } from "@maxeb/admin-ui";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons/faSpinnerThird";
import { faCheckCircle } from "@fortawesome/pro-duotone-svg-icons/faCheckCircle";
import { faExclamationTriangle } from "@fortawesome/pro-duotone-svg-icons/faExclamationTriangle";
export interface IProps {
  status: "init" | "pending" | "success" | "ok" | "error";
  errors: { [key: string]: string | undefined };
  children: React.ReactNode;
}

const style = (theme: ITheme) => ({
  wrapper: {
    position: "relative",
  },
  overlay: {
    position: "absolute",
    height: "100%",
    width: "100%",
    zIndex: 6,
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    textAlign: "center",
  },
  context: { position: "sticky", top: "80px", padding: "40px 0px" },
  pending: {
    "& > svg": { color: theme.palette.get("primary"), fontSize: "4rem" },
  },
  success: {
    "& > svg": { color: theme.palette.get("success"), fontSize: "7rem" },
  },
  error: {
    "& > svg": { color: theme.palette.get("danger"), fontSize: "7rem" },
  },
});

export default function StateHandler(props: IProps) {
  const classes = useClasses(style, props);
  return (
    <div className={classes.wrapper}>
      {props.status === "pending" && (
        <div className={classes.overlay}>
          <div className={classnames([classes.context, classes.pending])}>
            <FontAwesomeIcon icon={faSpinnerThird} spin />
          </div>
        </div>
      )}
      {props.status === "success" && (
        <div className={classes.overlay}>
          <div className={classnames([classes.context, classes.success])}>
            <FontAwesomeIcon icon={faCheckCircle} />
          </div>
        </div>
      )}
      {props.status === "error" && Object.keys(props.errors).length === 0 && (
        <div className={classes.overlay}>
          <div className={classnames([classes.context, classes.error])}>
            <FontAwesomeIcon icon={faExclamationTriangle} />
            <p>
              Ein <b>unbekannter Fehler</b> ist aufgetreten
              <br />
              Bitte versuchen Sie es später erneut.
            </p>
          </div>
        </div>
      )}
      {props.children}
    </div>
  );
}
