import React from "react";
import { useClasses, ITheme, Container, Row, Col } from "@maxeb/admin-ui";
import { Link } from "react-router-dom";
import logo from "../../resources/images/logo.svg";
import classnames from "classnames";
import { useLocation } from "react-router-dom";
import { TLinks } from "./Menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { faHeart } from "@fortawesome/pro-solid-svg-icons";
import WhishlistBTN from "./WhishlistBTN";
import { TWishlist } from "../../containers/Wishlist";

const styles = (theme: ITheme) => ({
  logo: {
    height: "50px",
  },
  link: {
    textDecoration: "none",
    color: "#a1a3a6",
  },
  active: {
    color: "#fff",
  },
});

export interface IProps {
  links: TLinks;
  wishlist: TWishlist;
}

const isActive = (link: IProps["links"][number], path: string) => {
  const { alias } = link;
  const isAlias =
    typeof alias !== "undefined" &&
    alias.some((item) => {
      return path.startsWith(item);
    });

  return link.to === path || isAlias;
};

function MenuLarge(props: IProps) {
  const classes = useClasses(styles);
  const location = useLocation();
  const path = location.pathname;

  return (
    <Container fluid>
      <Row verticalAlign="center" root={{ left: 0, right: 0 }} spacing={16}>
        <Col xs="auto">
          <img className={classes.logo} src={logo} alt="Artcare-Logo" />
        </Col>
        {props.links.map((link) => {
          return (
            <Col xs="auto" key={`link-for-${link.to}`}>
              <Link
                className={classnames([
                  classes.link,
                  isActive(link, path) && classes.active,
                ])}
                to={link.to}
              >
                {typeof link.label === "string" && link.label}
                {typeof link.label !== "string" && (
                  <FontAwesomeIcon icon={link.label} />
                )}
              </Link>
            </Col>
          );
        })}
        <Col xs="auto" key={`link-for-/wishlist`}>
          <WhishlistBTN
            wishlist={props.wishlist}
            isActive={isActive({ label: faHeart, to: "/wishlist" }, path)}
          />
        </Col>
        <Col xs="rest-fixed" md="hidden-down">
          <Row
            horizontalAlign="right"
            root={{ left: 0, right: 0 }}
            spacing={16}
          >
            <Col xs="30px">
              <a
                className={classes.link}
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/artcare_at/"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </Col>
            <Col xs="30px">
              <a
                className={classes.link}
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/artcare.at"
              >
                <FontAwesomeIcon icon={faFacebookSquare} />
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default MenuLarge;
