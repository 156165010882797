import React from "react";
import { useClasses, ITheme } from "@maxeb/admin-ui";
import classnames from "classnames";

export interface IProps {
  variant:
    | "subheadline"
    | "bodyheadline"
    | "miniheadline"
    | "paragraphheadline"
    | "h1"
    | "h2";
  children: React.ReactNode;
  noMargin?: boolean;
}

const style = (theme: ITheme) => ({
  headline: {
    marginTop: "0px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    margin: (props: IProps) => props.noMargin && "0px",
  },
  bodyheadline: {
    color: theme.palette.get("darkgrey"),
    textTransform: "uppercase",
    letterSpacing: "0.5px",
  },
  paragraphheadline: {
    margin: "8px 0px 0px 0px",
  },
  miniheadline: {
    fontSize: "0.8rem",
    fontWeight: 400,
    color: "#797979",
  },
});

export default function Headline(props: IProps) {
  const classes = useClasses(style, props);
  if (props.variant === "subheadline")
    return <h3 className={classes.headline}>{props.children}</h3>;
  if (props.variant === "bodyheadline")
    return (
      <h3 className={classnames([classes.headline, classes.bodyheadline])}>
        {props.children}
      </h3>
    );
  if (props.variant === "miniheadline")
    return (
      <h5 className={classnames([classes.headline, classes.miniheadline])}>
        {props.children}
      </h5>
    );
  if (props.variant === "paragraphheadline")
    return (
      <h4 className={classnames([classes.headline, classes.paragraphheadline])}>
        {props.children}
      </h4>
    );
  else if (props.variant === "h2")
    return <h1 className={classes.headline}>{props.children}</h1>;
  return <h1 className={classes.headline}>{props.children}</h1>;
}
